@use "../_global/_var" as *;
@use "../_global/_mixins" as *;

.page-content {
  display: flex;
  align-items: start;
  background-color: white;
  &-l {
    width: 650px;
  }
  &-r {
    width: 340px;
    position: sticky;
    z-index: 1;
    top: 0;
    .calender {
      background: none;
      padding: 0;
      margin: 1.5rem;
      .ttl {
        span {
          background-color: $red;
          &::after {
            border-color: $red transparent transparent transparent;
          }
        }
      }
      &-form {
        .form-cnt {
          display: block;
          .item {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
  @include mq(sp) {
    display: block;
    &-l {
      width: 100%;
      .page-ttl {
        margin-top: 0;
        .desc {
          margin-top: 0;
        }
      }
    }
    &-r {
      width: 100%;
    }
  }
}

.page-cnt {
  padding: 1.5rem;
}

.document-present {
  padding: 1.5rem;
  .dp-ttl {
    font-size: 23px;
    font-weight: bold;
    line-height: 1.2;
    color: $main;
    .line {
      background-color: $main;
    }
  }
  .ttl {
    font-size: 21px;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 1.5em;
    strong {
      font-size: 1.5em;
      color: $yellow;
    }
    span {
      padding: 0.5rem 1.25rem 1.125rem;
      display: block;
    }
  }
  @include mq(sp) {
    .flex {
      display: block;
    }
  }
}

.area-select {
  &-cnt {
    position: relative;
    z-index: 3;
    .ttl {
      font-size: 18px;
      text-align: center;
      letter-spacing: 0.1em;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    background-color: white;
    border: 1px solid #cccccc;
    padding: 0.5rem 1rem;
    margin-bottom: -1px;
    .select {
      background-color: $main;
      color: white;
      padding: 0.5rem 0.75rem;
      line-height: 1;
      min-width: auto;
      font-weight: bold;
      padding: 0.5rem 1rem;
      transition: all 0.4s;
      cursor: pointer;
      &:hover {
        background-color: darken($main, 10%);
      }
    }
    .text {
      width: calc(100% - 11rem);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
.area-select-wrapper {
  display: flex;
  width: 100%;
}

.area-select-item-label {
  background-color: $main;
  color: white;
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  @include mq(phone-sm-middle-l) {
    width: 25%;
    }
  @include mq(phone-sm-middle-m) {
    width: 30%;
  }
  @include mq(phone-small) {
    font-size: 11px;
  }
}

.area-select-item-content {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;

  .area.text {
    margin-right: 0.5rem;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .btn-radius.select {
    margin-left: 1rem;
  }
  @include mq(phone-sm-middle-l) {
    width: 75%;
  }
  @include mq(phone-sm-middle-m) {
    width: 70%;
  }
  @include mq(phone-small) {
    font-size: 11px;
  }
}


//CTA表示用
.area-select-wrapper-cta {
  display: flex;
  width: 100%;
}

.area-select-item-label-cta {
  font-size: 10px;
  background-color: $main;
  color: white;
  width: 32%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.area-select-item-content-cta {
  width: 68%;
  font-size: 10px;
}


.area-result-total {
  padding: 1rem 1.5rem 1.5rem;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  .num {
    background: linear-gradient(180deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5em;
  }
}

.batch-request {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $main;
  color: white;
  position: relative;
  z-index: 1;
  padding: 0.75rem 1.1rem;
  white-space: nowrap;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -23%;
    left: 18%;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 37px 20px 0 20px;
    border-color: #004f9d transparent transparent transparent;
  }
  .d-b {
    min-width: auto;
    background-color: #ccc;
    color: white;
    font-size: 16px;
    font-weight: bold;
  }
  .is-active {
    background: linear-gradient(360deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
    &::before {
      content: "";
      background: linear-gradient(180deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
      transition: all 0.4s;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 3rem;
    }
    &:hover {
      &::before {
        opacity: 0;
      }
    }
    span {
      color: white;
      font-weight: bold;
      position: relative;
      z-index: 1;
      display: inline-block;
    }
  }
  button[disabled] {
    cursor: no-drop;
  }
  .all-check {
    background: $gradOrange;
    color: white;
  }
  &-cnt {
    padding: 1.5rem;
  }
  @include mq(sp) {
    .label-checkbox-dummy {
      flex-shrink: 0;
    }
    .label-checkbox-txt {
      white-space: break-spaces;
      flex-grow: 1;
      line-height: 1.4;
    }
    &-item {
      width: 60%;
      &:last-child {
        width: 36%;
      }
      button {
        width: 100%;
        padding: 0.5rem;
      }
      .docu {
        font-size: 12px;
      }
    }
  }
}

.individual {
  & > .inr {
    padding: 1.5rem;
    margin-bottom: -1.5rem;
  }
  &:last-child {
    & > .inr {
      margin-bottom: 0;
    }
  }
  .tel {
    position: relative;
    z-index: 3;
    display: flex;
    .icon {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      background: linear-gradient(180deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
      padding: 1rem;
      img {
        height: 100%;
      }
    }
    .cnt {
      background-color: $bg-y;
      padding: 1rem 1.75rem;
      line-height: 1;
      flex-grow: 1;
      font-weight: bold;
      display: flex;
      align-items: center;
      .num {
        color: $orange;
        font-size: 26px;
        margin-left: 1rem;
      }
    }
  }
  &-cnt {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 2;
    .label-checkbox {
      display: block;
      &-input {
        width: 0;
        height: 0;
        display: block;
      }
      &-dummy {
        margin-bottom: 1rem;
      }
      &-txt {
        margin: auto;
        line-height: 1;
        letter-spacing: 2px;
        color: $main;
      }
    }
  }

  &-item-l {
    background: #f9f9f9;
    padding: 1rem;
  }
  &-item-r {
    flex-grow: 1;
    background-color: white;
  }
  &-item-r-cnt {
    padding: 1.5rem;
    hr {
      margin: 0.5rem auto;
    }
    .request {
      justify-content: space-between;
      .item {
        min-width: auto;
      }
    }
    .readmore {
      a {
        font-size: 20px;
        text-align: center;
        //margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        text-decoration: underline;
        color: $main;
      }
    }
  }
  &-ttl {
    padding: 1.5rem;
    color: white;
    line-height: 1.2;
    & > .inr {
      position: relative;
      z-index: 3;
      font-size: 26px;
      display: inline-flex;
      align-items: center;
      column-gap: 0.75rem;
      .label-txt {
        font-size: 11px;
        padding: 0.3rem 0.5rem 0.35rem;
        letter-spacing: 2px;
        border-radius: 4px;
      }
    }
  }
  &-labels {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    font-size: 12px;
    color: $main;
    .item {
      line-height: 1;
      padding: 0.5rem;
      border: 1px solid $main;
      font-weight: bold;
      border-radius: 4px;
    }
  }
  &-detail {
    display: flex;
    align-items: start;
    column-gap: 1rem;
    margin: 1rem auto;
    .thumb {
      aspect-ratio: 1 / 1;
      width: 100px;
      flex-shrink: 0;
      .img {
        height: 100%;
        object-fit: cover;
      }
    }
    .info {
      flex-grow: 1;
    }
    .price {
      font-weight: bold;
      margin-bottom: 0.25rem;
      &:first-child {
        margin-bottom: 0;
      }
    }
    .access,
    .address {
      display: flex;
      align-items: center;
      font-size: 12px;
      .icon {
        margin-right: 0.25rem;
        width: 18px;
        height: 18px;
        flex-shrink: 0;
      }
    }
    .access {
      margin-bottom: 0.5rem;
    }
  }
  &-review {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-weight: bold;
    margin: 1rem auto;
    .more {
      color: #0038ef;
      text-decoration: underline;
    }
    .consider {
      font-size: 12px;
      color: $main;
      background-color: $bg-y;
      line-height: 1;
      padding: 0.25rem;
    }
  }
  @include mq(sp) {
    .thumb {
      width: 80px;
    }
    .tel {
      .cnt {
        display: block;
        padding: 1rem;
        text-align: center;
        span {
          font-size: 10px;
          display: block;
        }
        .num {
          margin-left: 0;
          font-size: 20px;
        }
      }
    }
    &-review {
      flex-wrap: wrap;
      .more {
        flex-basis: 100%;
      }
      .consider {
        margin-top: 0.25rem;
      }
    }
    &-item-r-cnt {
      .link {
        font-size: 12px;
        line-height: 1.4;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    &-detail {
      .access {
        line-height: 1.4;
      }
    }
  }
}

.triangle {
  &-right {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 4px 0 4px 8px;
    border-color: transparent transparent transparent $main;
    transform: rotate(0deg);
    display: inline-block;
  }
}

.selected-temples {
  .ttl {
    font-size: 18px;
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
  }
  &-cnt {
    margin: 1.5rem;
    position: relative;
    z-index: 1;
    background-color: white;
    .individual-review {
      flex-wrap: wrap;
      .more {
        margin-bottom: 0.5rem;
      }
    }
    .individual-detail {
      .price {
        line-height: 1.4;
      }
      .thumb {
        width: 72px;
      }
      .access,
      .address {
        line-height: 1.2;
        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
    .individual-item-r-cnt {
      padding: 1rem 0.5rem;
    }
    .request {
      flex-wrap: nowrap;
      .item {
        font-size: 12px;
        padding: 0.5rem 0.75rem;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .tel {
    position: relative;
    z-index: 3;
    display: flex;
    font-size: 12px;
    .icon {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      background: linear-gradient(180deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
      padding: 0.75rem 0.5rem;
      img {
        height: 100%;
      }
    }
    .cnt {
      background-color: $bg-y;
      text-align: center;
      line-height: 1;
      flex-grow: 1;
      font-weight: bold;
      padding: 0.75rem 0;
      span {
        display: block;
      }
      .num {
        color: $orange;
        font-size: 20px;
        margin-left: 1rem;
      }
    }
  }
}
.form-temple-select {
  position: relative;
  z-index: 1;
  .batch {
    position: sticky;
    bottom: 0;
    z-index: 9;
    background-color: #ffffffeb;
    padding: 1.5rem;
    @include mq(sp) {
      padding: .7rem 1.5rem;
    }
    &-inr {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      @include mq(sp) {
        flex-direction: column;
        gap: .5rem;
      }
    }
    &-item {
      @include mq(sp) {
        width: 100%;
        text-align: center;
      }
      .docu {
        font-size: 20px;
        @include mq(sp) {
          font-size: 14px;
        }
      }
      button {
        @include mq(sp) {
          padding: .7rem;
          min-width: 164px;
        }
        &[disabled] {
          cursor: no-drop;
          background: #ccc;
          color: white;
          &::before {
            background: #ccc;
            color: white;
          }
        }
      }
      .label-num {
        font-size: 1.5rem;
        color: #da3a42;
        display: inline-block;
        padding: 0 0.2rem;
      }
    }
  }
  .no-data {
    padding: 1.5rem;
  }
}

.consider-type {
  color: white;
  &-ttl {
    text-align: center;
    font-size: 18px;
    margin-bottom: 1rem;
  }
  &-inr {
    position: relative;
    z-index: 1;
    padding: 1.5rem;
  }
}
.cost {
  padding: 1.5rem;
  &-name {
    font-weight: 700;
    font-size: 20px;
  }
}
.label-list {
  margin-top: 1rem;
  margin-bottom: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  &-item {
  }
}

.detail-cnt {
  padding: 3rem 1.5rem;
  margin-bottom: -1rem;
  &.recommend {
    .ttl {
      margin-bottom: 2rem;
    }
  }
  .inr {
    margin-bottom: 2rem;
  }
  .txt {
    font-weight: 500;
    p {
      margin: 1rem auto;
    }
    table {
      text-align: center;
      margin: 1rem auto;
      .th {
        background-color: $main;
        color: white;
      }
      td {
        padding: 0.5rem;
        border: 1px solid white;
      }
      .td {
        background-color: #ebf1f9;
      }
    }
  }
  &-map {
    aspect-ratio: 16/9;
    background-color: $gray2;
    iframe {
      height: 100%;
    }
  }
  &-txt {
    margin: 1rem auto;
  }
  .information {
    .title {
      margin: 1.5rem auto;
    }
  }
  .annotation {
    font-size: 12px;
  }
}
.slider {
  margin-bottom: 2rem;
}

//
.sidebar-cnt {
  margin: 1.5rem;
}
.more-cat {
  .title2 {
    text-align: center;
  }
  &-cnt {
    margin-top: 1.5rem;
  }
}
.other-plan {
  &-cnt {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    .item {
      flex-basis: calc((100% - 0.5rem) / 2);
    }
  }
}

// *********** jumokusou *************
.about {
  padding: 1.5rem;
  &.bg-gray {
    .bg-img {
      background-size: 2rem;
    }
  }
  &-name {
    font-size: 20px;
    margin-bottom: 1rem;
  }
  .title2 {
    margin-bottom: 1rem;
  }
}
.movie-section {
  margin-top: 10px;
  background-position: center;
}

.movie-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.movie-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.video-stream.html5-main-video {
  left: 0 !important;
}

.problem {
  &-ttl {
    font-size: 20px;
    margin-bottom: 1rem;
  }
  &-box {
    border: 1px solid;
    padding: 1.5rem;
    margin-bottom: 1rem;
    color: $main;
    &-ttl {
      font-size: 18px;
      margin-top: 0.5rem;
    }
  }
  &-btn {
    font-size: 16px;
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    transition: all 0.4s;
    .icon {
      margin-left: 0.75rem;
      path {
        stroke: $main;
      }
    }
    &:hover {
      background-color: $gray;
    }
  }
}
.choose-us {
  padding: 1.5rem;
  .ttl {
    font-size: 20px;
  }
  &-ttl {
    color: $main;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid;
    margin-bottom: 1.5rem;
    font-size: 18px;
    .feature {
      border-radius: 8px;
      letter-spacing: 2px;
      border: 1px solid;
      line-height: 1;
      padding: 0.5rem;
      font-size: 1rem;
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    .txt {
      display: block;
    }
  }
  &-cnt {
    padding: 1.5rem;
    background-color: white;
    margin: 1rem auto;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.structure {
  padding: 1.5rem;
  .ttl {
    font-size: 20px;
  }
  &-ttl {
    color: $main;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    &:before {
      content: "";
      width: 5rem;
      height: 2px;
      background-color: $main;
    }
    .feature {
      border-radius: 8px;
      letter-spacing: 2px;
      border: 1px solid;
      line-height: 1;
      padding: 0.5rem;
      font-size: 1rem;
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    .txt {
      display: block;
    }
  }
  &-cnt {
    padding: 1.5rem;
    background-color: white;
    margin: 1rem auto;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.flow {
  padding: 1.5rem;
  &-cnt {
    border: 1px solid $main;
    background-color: white;
    padding: 1.5rem;
    color: $main;
    font-size: 18px;
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 1rem;
    &:last-child {
      &::after {
        display: none;
      }
    }
    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 10px 9px 0 9px;
      border-color: $main transparent transparent transparent;
      transform: rotate(0deg);
      position: absolute;
      z-index: 1;
      bottom: -11px;
      left: 50%;
      transform: translateX(-50%);
    }
    &-icon {
      line-height: 1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1.5rem;
    }
    &-txt {
      flex-basis: calc(100% - 9%);
      text-align: center;
    }
  }
  .bg-img {
    background-size: 2rem;
  }
}
.types-costs {
  padding: 1.5rem;
  margin-top: -114px;
  padding-top: 135px;
  .plan-type-explanation{
    margin-top: 21px;
  }
  .plan-type-table{
    max-width: 100%;
    margin: 21px auto 21px;
    border-radius: 0;
    table-layout: auto;
    white-space: wrap;
    border: none;
    border-collapse: collapse;
    line-height: 1.75em;
  
    tbody{
      display: table-row-group;
      vertical-align: middle;
      unicode-bidi: isolate;
      border-color: inherit;
  
      tr{
        display: table-row;
        vertical-align: inherit;
        unicode-bidi: isolate;
        border-color: inherit;
        border-bottom: solid 2px #FFF;
  
        th{
          background: #0283B7;
          color: #FFF;
          white-space: nowrap;
          padding: 10px 20px;
          border: none;
          position: relative;
        }
  
        th:after{
          content: "";
          border: 10px solid transparent;
          border-left: 10px solid #0283B7;
          border-right: none;
          position: absolute;
          top: calc(50% - 10px);
          right: -10px;
        }
  
        td{
          background: #FDEDD2;
          text-align: left;
          padding: 10px 20px;
          border: none;
        }
      }
    }
  }
  .plan-type-pic{
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  &-header {
    margin-bottom: 2rem;
    .thumb {
      margin: 1.5rem -1.5rem -3rem;
      aspect-ratio: 16/5;
      .img {
        height: 100%;
        object-fit: cover;
      }
      .thumb-img{
        height: auto;
      }
    }
    .ttl-box {
      border: 1px solid $main;
      box-shadow: 4px 4px 14px rgba(5, 39, 78, 0.3);
      text-align: center;
      position: relative;
      z-index: 1;
      .bg-white {
        padding: 1.5rem;
      }
      .sub-ttl {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .price {
        .num {
          color: $main;
          font-size: 18px;
        }
      }
      .types {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        margin-top: 0.5rem;
      }
    }
  }
  &-cnt {
    .subtitle {
      margin: 1.5rem auto;
      color: $main;
      font-size: 18px;
    }
  }
  .bg-img {
    background-size: 2rem;
  }
}

.notfound {
  text-align: center;
  max-width: 640px;
  .ttl {
    margin-bottom: 4rem;
  }
  p {
    margin: 1rem auto;
  }
  .gsc-control-cse.gsc-control-cse-ja {
    padding: 0;
    background: none;
    border: none;
  }
  table {
    tr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .gsc-input {
      flex-grow: 1;
      padding-right: 0 !important;
    }
    .gsc-search-button {
      margin: 0;
    }
  }
  @include mq(sp) {
    width: 90%;
    margin: auto;
    table {
      tr {
        gap: initial;
        align-items: stretch;
      }
      .gsc-search-button {
        flex-shrink: 0;
        width: 3rem;
      }
      .gsc-input-box {
        padding: 0;
      }
    }
  }
}

// *********** simulation *************
.simulation {
  background-size: cover;
  background-position: center;

  img {
    width: auto;
    margin: auto;
  }
  p {
    &:not(.error-txt) {
      margin: 2rem auto;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  label {
    color: $blue3;
    cursor: pointer;
    &.on {
      background: $gradGeen;
      color: white;
    }
  }
  .fz-ss {
    color: $black;
  }
  .btn {
    color: $blue3;
    font-size: 1rem;
    &[disabled] {
      cursor: no-drop;
      background: #ccc;
      &::before {
        background: #ccc;
      }
    }
  }
  &.top {
    .simulation-main {
      padding-top: 2rem;
    }
    .simulation-maxw{
      max-width: 480px;
      margin: auto;
    }
    .simulation-info {
    }
  }
  &-frame {
    padding: 3% 0;
    @include mq(sp) {
      padding: 5% 0;
    }
    &.result-container {
      padding: 3% 0;
      @include mq(sp) {
        padding: 0;
      }
    }
  }
  &-cnt {
    background-color: rgb(255 255 255 / 90%);
    color: $blue3;
    border: 1px solid $blue3;
    box-shadow: 0px 6px 14px rgba(0, 104, 183, 0.25);
    padding: 4rem 2rem;
    position: relative;
    z-index: 1;
    &.result {
      background-color: $blue3;
      color: white;
      .simulation-info {
        position: relative;
        top: initial;
        left: initial;
        margin-bottom: 2rem;
        .item {
          background-color: white;
          color: $blue3;
          &::after {
            border-color: white rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
          }
        }
      }
      .simulation-main {
        padding-top: 0;
      }
      .calender {
        padding: 0;
        margin-top: 1rem;
        &-cnt {
          color: $black;
        }
        .ttl2 {
          font-size: 16px;
          margin-top: 1rem;
        }
        .caption {
          color: $black;
          text-align: left;
        }
      }
    }
    &.result2 {
      .simulation-info {
        position: relative;
        top: initial;
        left: initial;
        margin-bottom: 2rem;
      }
      .calender {
        padding: 0;
        .ttl {
          margin-bottom: 2rem;
        }
        .txt {
          font-size: 16px;
        }
      }
    }
  }
  &-info {
    text-align: center;
    position: absolute;
    z-index: 1;
    top: -2.5rem;
    left: 0;
    width: 100%;
    .item {
      color: white;
      font-size: 21px;
      position: relative;
      z-index: 2;
      background-color: $blue3;
      &::after {
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 12px 7px 0 7px;
        border-color: $blue3 transparent transparent transparent;
        transform: rotate(0deg);
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%);
      }
      &-wrap {
        cursor: initial;
      }
    }
  }
  &-main {
    text-align: center;
    .top-text {
      font-size: 16px;
    }
    .result-cnt-cost {
      display: inline-block;
      margin: auto;
      line-height: 1.2;
      padding: 1rem;
      background-color: #fff3f3;
      color: $black;
      span {
        font-size: 18px;
      }
      .num {
        color: $red;
        font-size: 36px;
        margin: 0 0.25rem;
      }
    }
    .result-cnt-desc {
      font-weight: 500;
      font-size: 16px;
      .desc-label {
        display: inline-block;
        background-color: $blue3;
        color: white;
        line-height: 1.2;
        padding: 0.25rem;
        margin: 0.25rem;
      }
    }
    .cta {
      color: $black;
    }
    .reader {
      font-weight: bold;
      font-size: 3rem;
      line-height: 1;
      div {
        margin-bottom: -0.75rem;
      }
    }
  }
  &-start {
    margin-top: 2rem;
    &::after {
      content: "※概算の金額を参考までに表示するものです";
      font-size: 10px;
      color: $black;
      margin-top: 0.5rem;
    }
  }
  &-step {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    gap: 0.75rem;
    &-item {
      background-color: #c0daed;
      color: white;
      padding: 0.5rem 0.75rem;
      line-height: 1;
      text-align: center;
      font-size: 20px;
      &.current {
        opacity: 1;
        background-color: $blue3;
      }
      &.last {
        background-color: #fce7b2;
        &.current {
          background-color: $yellow;
        }
      }
    }
    .hr {
      width: 2rem;
      height: 1px;
      background-color: $blue3;
    }
  }
  &-select {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    &-item {
      font-size: 18px;
      font-weight: 500;
    }
  }
  &-ttl {
    font-size: 21px;
    text-align: center;
    letter-spacing: 1px;
    &::after {
      content: "";
      width: 3rem;
      height: 1px;
      background-color: $blue3;
      margin: 0.5rem auto 0;
    }
    .t1 {
      display: block;
    }
    .t2 {
      display: block;
      font-size: 20px;
      font-weight: 500;
    }
    &.last {
      position: relative;
      z-index: 1;
      &::after {
        display: none;
      }
      .btn {
        border-color: $blue3;
      }
      .triangle {
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 13px 8.5px 0 8.5px;
        border-color: $blue3 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        transform: rotate(0deg);
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
      }
      .t1 {
        position: relative;
        margin-bottom: 1rem;
      }
      .tri2 {
        border-color: white transparent transparent transparent;
        bottom: -10px;
      }
    }
  }
  &-area-select {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    .area-group {
      .sub {
        margin: 0.5rem 0 0 1.5rem;
        &-item {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
  &-check-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffc9;
    z-index: 9;
    display: none;
  }
  &-loading {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    width: 10rem;
    height: 10rem;
    transform: translate(-50%, -64%);
    .img {
      animation: rotation 1s linear infinite;
      @keyframes rotation {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .txt {
      text-align: center;
      margin-top: 1.5rem;
      font-size: 21px;
      animation: flashing 1s linear infinite;
      @keyframes flashing {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
  &-form {
    p {
      &:not(.error-txt) {
        font-weight: 500;
        font-size: 16px;
      }
    }
    .form-item {
      input {
        padding: 1rem;
        border: 1px solid #0068b7;
        background: #edf6fd;
        box-shadow: 5px 5px 11px -6px #0068b78c inset;
        max-width: 600px;
        &::placeholder {
          color: #393939;
          font-weight: bold;
        }
      }
    }
  }
  @include mq(sp) {
    &-frame {
      width: 90%;
    }
    &-select {
      &-item {
        flex-basis: calc((100% - 1rem) / 2);
        label {
          padding: 1rem 0;
        }
        &.sp-col {
          flex-basis: 100%;
        }
      }
    }
    &-area-select {
      gap: 1rem;
      padding: 1rem;
      border: 1px solid;
      max-height: 50vh;
      overflow: auto;
      .area-group {
        flex-basis: 100%;
      }
    }
    &-cnt {
      &.result {
        padding: 1.5rem;
        margin-top: 4rem;
        .result-container & {
          @include mq(sp) {
            margin-top: 2rem;
          }
        }
        .calender {
          width: 100%;
          margin-top: 1.5rem;
          .form-cnt {
            color: $black;
            .item {
              margin-bottom: 1rem;
            }
          }
        }
      }
      &.result2 {
        padding: 1rem;
        .sp-reset {
          margin: 0 -10%;
          .cta {
            background: none;
            .small {
              margin: 0;
            }
          }
        }
        .calender {
          width: 100%;
          &-cnt {
            color: $black;
          }
          &-form {
            color: $black;
            .form-item {
              margin-bottom: 1rem;
            }
            label {
              color: $black;
            }
          }
        }
      }
    }
  }
  .simulation-width{
    margin-top: 20px;
  }
}
.indispensable-text{
  text-align: left;
  margin-bottom: 0.5rem;

}

.indispensable-label{
  font-size: 16px;
  color: white;
  padding: 0.1rem 0.8rem 0.2rem;
  background-color: #F77C00;
  border-radius: 4px;
  background-color: #F77C00;
}

// *********** privacy policy *************
.privacy {
  &-cnt {
    font-weight: normal;
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      margin-bottom: 1rem;
    }
    p {
      padding-bottom: 0.75rem;
    }
    .inr {
      padding: 2rem 0;
      margin: 2rem auto;
      border-top: 1px solid $main;
    }
  }
  @include mq(sp) {
    width: 90%;
  }
}

// *********** company *************
.company {
  &-cnt {
    ul {
      font-weight: normal;
      list-style: disc;
      margin-left: 1.25rem;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1rem;
      font-weight: normal;
    }
    .btn {
      min-width: 24rem;
    }
  }
  @include mq(sp) {
    width: 90%;
  }
}

// *********** magazine *************
.table-of-contents {
  padding: 1.5rem;
  color: $main;
  .ttl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: $black;
    svg {
      path {
        fill: $black;
      }
    }
  }
  .btn-radius {
    padding: 0.25rem 1rem;
    min-width: 108px;
  }
}
.publication {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 1rem;
  margin: 1.5rem;
  .hr {
    width: 1px;
    height: auto;
  }
}
.magazine {
  &-cnt {
    margin: 1.5rem;
    p {
      font-weight: normal;
      margin: 1.25rem auto;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h2 {
      color: white;
      margin: 2.5rem -1.5rem;
      background-color: $main;
      padding: 1.5rem;
      position: relative;
      z-index: 1;
      font-size: 23px;
      &::after {
        background-image: url(../img/bg-white.svg);
        background-size: 1em;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        opacity: 0.15;
      }
      span {
        position: relative;
        z-index: 2;
        display: block;
      }
      .num {
        display: inline-block;
        border: 1px solid;
        border-radius: 7px;
        font-size: 1rem;
        line-height: 1;
        padding: 0.25rem 0.25rem 0.4rem 0.3rem;
        letter-spacing: 2px;
      }
    }
    h3 {
      font-weight: 700;
      color: $main;
      position: relative;
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
      &:first-child {
        margin-top: 0;
      }
      &::before {
        content: "";
        display: block;
        width: 70%;
        height: 3px;
        background-color: $main;
        position: absolute;
        bottom: 0px;
        @include mq(sp) {
          width: 100%;
        }
      }
    }
    h4 {
      color: $main;
      font-size: 18px;
    }
    h5 {
      color: $main;
      font-size: 16px;
      font-weight: bold;
    }
    h6 {
      font-size: 16px;
      font-weight: bold;
    }
    .pr-box {
      border: 1px solid $main;
      &-cnt {
        padding: 1.5rem;
      }
      &-ttl {
        font-size: 18px;
        line-height: 1.4;
        padding: 1rem;
      }
    }
    .area {
      margin: -2rem -1.5rem -1.5rem;
      padding: 1.5rem;
    }
    .area-cta {
      margin: 0rem;
      padding: 1.5rem;
    }
    .search-cta {
      @include mq(sp) {
        width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &-bottom {
    .bottom-section {
      margin: 0 1.5rem;
      .recommended-graves {
        margin: 0 -1.5rem;
      }
    }
    .article-footer {
      margin-top: 1.5rem;
    }
    .recommended-slide {
      margin-top: 1.5rem;
      h3 {
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
    .area {
      padding: 1.5rem;
    }
  }
}
.talk {
  margin: 2rem auto;
  &-item {
    display: flex;
    gap: 0.5rem;
    max-width: 80%;
    margin-bottom: 1rem;
  }
  &-icon {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }
  &-txt {
    border-radius: 16px;
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: white;
      top: -6px;
      left: -4px;
      z-index: -1;
      position: absolute;
    }
    &::before {
      content: "";
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      background-color: #ebf1f9;
      position: absolute;
      top: 1px;
      left: -5px;
      z-index: -1;
    }
    p {
      margin-bottom: 0;
      padding: 1rem;
    }
    .bg-blue-l {
      border-radius: 16px;
    }
  }
  &:nth-child(odd) {
    .talk-item {
      flex-direction: row-reverse;
      margin-left: auto;
    }
    .talk-txt {
      &::before {
        left: initial;
        right: -5px;
      }
      &::after {
        left: initial;
        right: -4px;
      }
    }
  }
  @include mq(sp) {
    max-width: 100%;
  }
}
.recommended-graves {
  margin: 2rem -1.5rem;
  .inr {
    padding: 1.5rem;
  }
  h3 {
    color: $black;
    &::before {
      display: none;
    }
  }
  &-slider {
    margin: 1rem auto 0;
    max-width: 520px;
    &-item {
      width: 204px !important;
      margin: 0.5rem;
    }
    .slick-next {
      right: -39px;
    }
    .slick-prev {
      left: -40px;
    }
  }
  @include mq(sp) {
    &-slider {
      .slick-next {
        right: -12px;
      }
      .slick-prev {
        left: -12px;
      }
    }
  }
}
.recommend {
  @include mq(sp) {
    .flex {
      gap: 2rem !important;
      .flex-item {
        flex-basis: 100% !important;
      }
    }
  }
}

.table-of-contents {
  &-cnt {
    margin-bottom: 1rem;
  }
  &-list {
    margin-left: 1rem;
    font-size: 14px;
    //margin-bottom: 1rem;
    &.lv_h3 {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 1rem;
      margin-left: 2rem;
      font-weight: 500;
      //margin-bottom: 1rem;
      &::before {
        content: "";
        width: 20px;
        height: 2px;
        background-color: $main;
      }
    }
    // 作成者の意図がわからないため、削除ではなくコメントアウトしました。
    //&.lv_h2 {
    //  &:nth-child(2) {
    //    display: none;
    //  }
    //}
    .sub {
      font-size: 1.15rem;
      .item {
        margin-top: 0.25rem;
        margin-left: -6px;
        margin-bottom: 0.25rem;
        .link {
          display: flex;
          align-items: center;
          gap: 1rem;
          &::before {
            content: "";
            width: 5rem;
            height: 2px;
            background-color: $main;
          }
        }
      }
    }
  }
}
.table-price-plan {
  text-align: center;
  .th {
    background-color: $main;
    color: white;
  }
  td {
    padding: 0.5rem;
    border: 1px solid white;
  }
  .td {
    background-color: #ebf1f9;
  }
}

.article-footer {
  border: double 3px $main;
  position: relative;
  z-index: 1;
  &-inr {
    padding: 1.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
    .thumb {
      width: 90px;
      aspect-ratio: 1/1;
      .img {
        height: 100%;
        object-fit: cover;
      }
    }
    .txt {
      span {
        vertical-align: middle;
      }
      .name2 {
        font-size: 18px;
        font-weight: bold;
        color: $main;
        margin-left: 0.5rem;
      }
    }
    .desc {
      flex-basis: 100%;
    }
  }
  .triangle {
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 18px 18px 0 0;
    border-color: $main transparent transparent transparent;
    transform: rotate(0deg);
    position: absolute;
    z-index: 2;
    &1 {
      @extend .triangle;
      top: -4px;
      left: -4px;
    }
    &2 {
      @extend .triangle;
      transform: rotate(90deg);
      top: -4px;
      right: -4px;
    }
    &3 {
      @extend .triangle;
      transform: rotate(180deg);
      bottom: -4px;
      right: -4px;
    }
    &4 {
      @extend .triangle;
      transform: rotate(270deg);
      bottom: -4px;
      left: -4px;
    }
  }
}

// *********** news(page) *************
.news-list-item {
  padding-bottom: 1rem;
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 1rem;
}

// *********** reservation-form *************
.reservation-form {
  &-header {
    padding: 2rem 0;
    .icon-txt {
      font-size: 12px;
    }
  }
  &-ttl {
    color: $main;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 23px;
  }
  &-item {
    padding: 0.75rem !important;
    background-color: white;
    border-bottom: none;
    .icon-txt {
      font-size: 12px;
    }
  }
  &-cnt {
    background-color: white;
    padding: 2rem;
    .ttl {
      font-size: 18px;
    }
    .calender {
      padding: 0;
    }
    .calender-form {
      padding: 0%;
      margin-top: 1rem;
      .form-item {
        gap: 0.5rem;
      }
    }
  }
  @include mq(sp) {
    &-cnt {
      padding: 2rem 0;
    }
  }
}

// *********** single *************
.post-cnt {
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  figure {
    margin: 1.5rem;
    &:first-child {
      margin-bottom: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h2 {
    font-size: 18px;
    color: white;
    margin: 2.5rem 0;
    background-color: $main;
    padding: 1.5rem;
    position: relative;
    z-index: 1;
    font-size: 23px;
    &::after {
      background-image: url(../img/bg-white.svg);
      background-size: 1em;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      opacity: 0.15;
    }
    span {
      position: relative;
      z-index: 2;
      display: block;
    }
  }
  h3 {
    color: $main;
    font-size: 20px;
  }
  h4 {
    color: $main;
    font-size: 18px;
    font-weight: bold;
  }
  h5 {
    color: $main;
    font-size: 16px;
    font-weight: bold;
  }
  h6 {
    font-size: 16px;
    font-weight: bold;
  }
  p {
    font-weight: normal;
  }
  a {
    display: inline;
    text-decoration: underline;
    &:hover {
      color: $blue;
    }
  }
  ul {
    list-style: disc;
    font-weight: normal;
    margin: 1.5rem;
    padding-left: 1.3rem;
  }
  .ttl {
    margin: 2rem auto;
    color: white;
    padding: 1.5rem;
    font-size: 23px;
  }
  .event-box {
    margin: 1.5rem;
    border: 1px solid $main;
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    figure {
      margin: 1.5rem 0;
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
    ul {
      margin: 0 1.5rem;
    }
    &-ttl {
      padding: 0.5rem 1rem;
      color: $main;
      font-size: 18px;
    }
    &-cnt {
      padding: 1.5rem;
      p {
        margin: 0;
      }
    }
  }
}

//
.customer {
  &-cnt {
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
    .load-more {
      cursor: pointer;
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(255 255 255 / 78%) 40%, rgba(255, 255, 255, 0.6068802521) 80%, rgba(255, 255, 255, 0) 100%);
      padding: 2rem 0;
      .btn {
        min-width: 13rem;
        background-color: $main;
        color: white;
        font-weight: bold;
        font-size: 1rem;
        padding: .5rem;
        transition: all 0.2s;
        margin: auto;
        font-weight: bold;
        padding: 0.5rem;
        &:hover {
          background-color: $gray;
          color: $main;
        }
      }
    }
  }
  &-box {
    border: 1px solid $main;
    padding: 1.5rem;
    // height: 23rem;
    transition: all 0.4s;
  }
  &-meta {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    .icon {
      width: 60px;
      height: 60px;
    }
  }
  &-faq {
    margin-top: 1rem;
    .q {
      font-size: 16px;
      font-weight: bold;
      color: $main;
      margin-bottom: 0.25rem;
    }
  }
}

// 口コミ
.temple-score {
  margin-top: 0.3rem;
  @include mq(phone-sm-middle-m) {
    margin-top: 0;
  }
  .stars {
    display: inline-block;
    margin-right: 0.5rem;
    .star {
      font-size: 20px;
      color: lightgray;
      &.filled {
        color: red;
      }
    }
  }
  span {
    color: red;
    font-size: 20px;
  }
}
.customer-meta-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(phone-sm-middle-m) {
    display: block;
  }
  .post-date {
    font-size: 15px;
    color: $black;
    @include mq(phone-sm-middle-m) {
      margin-top: 0.4rem;
    }
  }
}
// request-info
.request-info {
  margin-bottom: -5rem;
  .label-checkbox-dummy {
    background: white;
    border: 1px solid #D7D7D7;
  }
  .form-item {
    gap: 1rem;
    @include mq-max(512px) {
      gap: .5rem;
    }
  }
  .calender-form {
    padding-bottom: 2.5rem;
  }
  .supplement-text {
    margin-top: 1rem;
    margin-bottom: 1rem;
    p {
      text-align: center;
      font-size: 15px;
    }
    br {
      display: none;
      @include mq(phone-sm-middle-l) {
        display: inline;
      }
    }
  }
  &-header {
    padding: 2rem;
    @include mq(phone-medium) {
      padding: 1rem;
    }
    .ttl {
      color: $main;
      text-align: center;
      font-weight: bold;
      font-size: 23px;
      margin-bottom: 1.5rem;
    }
    .ttl-left {
      text-align: left;
      font-size: 20px;
    }
    .complete-ttl {
      @include mq(phone-sm-middle-l) {
        font-size: 18px;
      }
      @include mq(phone-sm-middle-m) {
        font-size: 14px;
      }
    }
    .ttl-section {
      text-align: center;
      margin-bottom: 10px;
    }
    .marker-yellow {
      background-color: $yellow-l;
    }
    .tx-red {
      color: $red;
      border-bottom: 1px solid $red;
    }
    .desc {
      background-color: white;
      padding: 1rem;
      max-width: 710px;
      margin: auto;
    }
    .article-layout2-txt {
      .ttl {
        color: inherit;
        text-align: inherit;
        font-weight: inherit;
        font-size: inherit;
        margin-bottom: inherit;
      }
    }
  }
  &-cnt {
    .pp {
      text-align: center;
      margin-bottom: 2rem;
      .ttl {
        font-size: 16px;
        margin: 1.5rem auto .5rem;
      }
      .link {
        display: inline;
        color: $blue;
        text-decoration: underline;
      }
      .label-checkbox {
        width: 100%;
        justify-content: center;
      }
    }
    &.thanks {
      background: #FFF8DD;
      text-align: center;
      padding: 2rem;
      .btn {
        display: inline-flex;
        align-items: center;
        gap: 4px;
      }
      .txt {
        line-height: 1;
      }
      svg {
        width: .9rem;
        height: .9rem;

      }
    }
  }
}

.request-peace-text{
  display: flex;
  justify-content: center;
  color: $red2;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.request-download {
  .ttl {
    &:not(.ttl-border-l) {
      margin-bottom: 3rem;
    }
  }
  .request-info-cnt {
    .ttl {
      text-align: inherit;
    }
  }
  &__area-section {
    margin-bottom: 2.3rem;
    .download-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba($main, .6);
      padding: 1rem 0;
      @include mq(sp) {
        flex-direction: column;
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      &-list {
        padding: 0 1.5rem;
      }
      &__temple {
        width: 75%;
        @include mq(sp) {
          width: 100%;
          margin-bottom: 1rem;
        }
        span {
          display: block;
        }
        .name {
          font-size: 16px;
          font-weight: 600;
        }
        .address {
          font-weight: normal;
        }
      }
      &__btn {
        text-align: center;
        @include mq(sp) {
          width: 100%;
        }
        .btn {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          padding: 0.5rem 2rem;
          min-width: 215px;
          svg {
            width: .9rem;
            height: .9rem;
          }
        }
      }
    }
  }
  .anchor-links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    span {
      display: flex;
      margin-bottom: 5px;
      align-items: center;
      &::after {
        content: "/";
        margin: 0 0.25rem;
        font-size: 12px;
      }
      &:last-child::after {
        content: none;
      }
      a {
        text-decoration: none;
        color: #004f9d;
        font-weight: bold;
        &:hover {
          color: #0056b3;
          text-decoration: underline;
        }
      }
    }
  }
}

.single-temple {
  .page-content-r {
    .temple-has-plans {
      .consider-type {
        margin: 0 1.5rem;
        @include mq(sp) {
          margin: 0;
        }
      }
    }
  }
}

.article {
  &-layout2 {
    &:not(.reservation-form-item) {
      display: block;
    }
  }
  &-post-list {
    &-title {
      position: relative;
      .ttl {
        width: 100%;
        margin: 1rem 0;
        padding-right: 70px;
        @include mq(sp) {
          padding-right: 60px;
        }
        .article-layout2:hover & {
          color: $main;
        }
      }
      .icon {
        position: absolute;
        top: 50%;
        left: auto;
        right: 2rem;
        transform: translate(50%, -50%);
        z-index: 1;
        color: $main;
        .article-layout2:hover & {
          transform: translate(130%, -50%);
        }
        path {
          fill: currentColor;
        }
      }
      &.color-white {
        color: #fff;
        .ttl {
          .article-layout2:hover & {
            color: #fff;
          }
        }
        .icon {
          color: #fff;
        }
      }
    }
  }
}

.temp-info ul li::marker {
  color: $main;
}

.no-px {
  padding-left: 0;
  padding-right: 0;
}

.has-vivid-yellow-color {
  color: #fce000;
}

.footer-cta {
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: $bg-b;
  position: sticky;
  bottom: 0;
  z-index: 3;
  text-indent: .1em;
  letter-spacing: .1em;
  @include mq-max('640px') {
    padding: 1rem .8rem;
  }
  @include mq-max('380px') {
    padding: .5rem .8rem 1rem;
  }

  &-title {
    text-align: center;
    margin-bottom: 1rem;
    color: $white;
    @include mq-max('640px') {
      margin-bottom: .5rem;
    }
  }

  &-items {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &-item {
    width: 32%;
    border-radius: 8px;
    color: $white;
    padding: .7rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .4s;
    flex-wrap: wrap;
    @include mq-max('640px') {
      width: 37%;
      padding: .5rem .3rem;
    }

    &-est {
      margin-top: 14px;
      width: 100%;
    }

    .label {
      display: inline-block;
      padding: 0 5px;
      margin-right: .5rem;
      border-radius: 3px;
      @include mq-max('380px') {
        font-size: 12px;
      }
    }

    &-title {
      font-weight: bold;
      font-size: 16px;
      @include mq-max('640px') {
        line-height: 1.2;
        font-size: 12px;
      }
      @include mq-max('380px') {
        font-size: 10px;
      }
      &:first-child {
        @include mq-max('640px') {
          width: 100%;
        }
      }
    }
    &-desc {
      width: 100%;
      margin-bottom: 5px;
      text-align: center;
      @include mq-max('640px') {
        font-size: 11px;
        line-height: 1.4;
      }
      @include mq-max('400px') {
        font-size: 10px;
      }
      br {
        display: none;
        @include mq-max('360px') {
          display: block;
        }
      }
    }
    &-title-cta1 {
      font-weight: bold;
      font-size: 16px;
      @include mq-max('870px') {
        line-height: 1.2;
        font-size: 13px;
      }
      @include mq-max('800px') {
        line-height: 1.2;
        font-size: 11px;
      }
      @include mq-max('767px') {
        line-height: 1.2;
        font-size: 14px;
      }
      @include mq-max('640px') {
        line-height: 1.2;
        font-size: 12px;
      }
      @include mq-max('380px') {
        font-size: 10px;
      }
      &:first-child {
        @include mq-max('640px') {
          width: 100%;
        }
      }
    }
  }


  &-bg-white {
    background-color: $white;
    &:hover {
      background-color: $ho-w;
      transition: all .4s;
    }
    @include mq-max('640px') {
      width: 22%;
    }

    .footer-cta-item-title {
      color: $black;
      @include mq-max('640px') {
        text-align: center;
      }
    }

    img {
      width: 12px;
      display: inline-block;
      vertical-align: sub;
      @include mq-max('640px') {
        width: 9px;
      }
    }
  }

  &-bg-white-cta1 {
    background-color: $white;
    &:hover {
      background-color: $ho-w;
      transition: all .4s;
    }
    @include mq-max('640px') {
      width: 22%;
    }

    .footer-cta-item-title-cta1 {
      color: $black;
      @include mq-max('640px') {
        text-align: center;
      }
    }

    img {
      width: 12px;
      display: inline-block;
      vertical-align: sub;
      @include mq-max('640px') {
        width: 9px;
      }
    }
  }

  &-bg-red {
    background-color: $bg-r;
    &:hover {
      background-color: $ho-r;
      transition: all .4s;
    }
    .label {
      background-color: $white;
      color: $bg-r;
    }
  }

  &-bg-green {
    background-color: $bg-g2;
    &:hover {
      background-color: $ho-g;
      transition: all .4s;
    }
    .label {
      background-color: $white;
      color: $bg-g2;
    }
  }
  &-bg-orange {
    background-color: $label-chip-required;
    &:hover {
      background-color: $orange;
      transition: all .4s;
    }
    .label {
      background-color: $white;
      color: $label-chip-required;
    }
  }
  &-bg-bule {
    background-color: $main;
    &:hover {
      background-color: $blue2;
      transition: all .4s;
    }
    .label {
      background-color: $white;
      color: $main;
    }
  }
}

.footer-cta-top{
  z-index: 3;
}

.sns-x-br {
  display: none;
  @include mq-max('387px') {
    display: block;
  }
}

.is-sp {
  display: none;
  @include mq-max('640px') {
    display: block;
  }
}
#pri_ttl {
  padding-top: 4rem;
  margin-top: -1rem;
}
.annotation-style{
  font-size: 12px;
  color: $label-chip-any;
  @include mq-max('400px') {
    font-size: 10px;
  }
}
.container-small {
  .tour-ttl {
    font-size: 18px;
  }
}
.detail-cnt-move {
  padding: 0rem 1.5rem;
}

.detail-cnt-top-padding {
  padding-top: 3rem;
}
/*---------------------------------------------------------------------------------
 search_ohaka_cta用オーバーライドStyle
---------------------------------------------------------------------------------*/
.custom-span {
  color: initial !important;
  margin: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
  position: static !important;
  z-index: auto !important;
  font-size: 14px !important;
  &::after {
    background-image: none !important;
    background-size: auto !important;
    position: static !important;
    z-index: auto !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
    height: auto !important;
    content: "" !important;
    opacity: 1 !important;
  }
}
.custom-p {
  margin: .5rem auto !important;
}

.magazine-cnt-org-cls {
  > p,
  > .section-small,
  > .wp-block-table,
  > blockquote,
  > ul,
  > ol {
    font-size: 16px;
  }
}


/*---------------------------------------------------------------------------------
  カレンダー
---------------------------------------------------------------------------------
 */
.reservation-form-cnt{
  position: relative;
}

.date-modal-content {
  background-color: #fefefe;
  width: 100%;
  margin-bottom: 1.5em;
}

.calendar-form{
  position: absolute;
  left: 0px;
  top: 75px;
}

.past-date{
  background-color: #A9A9A9;
}

/* datepickerのスタイル */
.ui-datepicker {
  font-size: 14px;
  width: 100%;
}

.ui-datepicker-header {
  position: relative;
  padding: 10px 0;
  background: #f0f0f0;
  border: 1px solid #CCCCCC;
  border-bottom: none;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
  top: calc(50% - 5px);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.ui-datepicker-prev {
  left: 10px;
}

.ui-datepicker-next {
  right: 10px;
}

.ui-datepicker-prev::before,
.ui-datepicker-next::before {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.ui-datepicker-prev::before {
  left: 0;
  border-right: 10px solid #333;
}

.ui-datepicker-next::before {
  right: 0;
  border-left: 10px solid #333;
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-calendar {
  width: 100%;
  border-collapse: collapse;
}

.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
  padding: 5px;
  text-align: center;
  border: 1px solid #CCCCCC;
}

.ui-datepicker-calendar .ui-state-default {
  display: block;
  padding: 5px;
  text-decoration: none;
  color: #333;
}

.ui-datepicker-calendar .ui-state-active {
  background: #007bff;
  color: #fff;
}

.ui-datepicker .day-sunday a,
.ui-datepicker .day-holiday a {
  color: red !important;
}

.ui-datepicker .day-saturday a {
  color: blue !important;
}

.future-date::after {
  content: "";
  width: 1rem;
  height: 1rem;
  border: 2px solid #f7b200;
  border-radius: 50%;
  margin: 0.25rem auto 0;
}

.holidays::after {
  content: "×";
  display: block;
  font-size: 1rem;
  color: red;
  text-align: center;
  margin: 0.25rem auto 0;
  font-weight: bold;
}

.ui-datepicker-calendar .future-date {
  cursor: pointer;
}

.old-calendar-form{
  margin-bottom: 0;
  border: 0;
}

/*---------------------------------------------------------------------------------
  現地スタッフのスタイル
---------------------------------------------------------------------------------*/
.recom_report{
    margin-bottom: 15px;
  @include mq(sp) {
    display:block;
    .flex-item{
      margin:20px 0px;
    }
  }
}

.recom_font{
  font-weight: 500;
}

.message-container {
  margin: 0 auto;

  .message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .message > div:first-child {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .message .icon img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #004f9d;
  }

  .icon {
    margin-top: 20px;
  }

  .message .name {
    font-size: 12px;
    color: #004f9d;
    margin-top: 2px;
    text-align: center;
  }

  .parent_bubble{
    width: 100%;
    min-height: 110px;
    height: 100%;
    display: flex;
  }

  .message .bubble {
    background-color: #f2f2f2;
    padding: 10px 15px;
    border: #004f9d;
    width: 100%;
    position: relative;
  }

  .message .bubble:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  .recom_qest {
    justify-content: flex-end;
  }

  .recom_qest > div:first-child {
    width: 90px;
    order: 2;
    margin-right: 0;
    margin-left: 10px;
  }

  .recom_qest .bubble {
    margin: auto 0;
    background-color: white;
    border: 1px solid #004f9d;
  }

  .recom_qest .bubble:after {
    border-left: 10px solid #004f9d;
    right: -10px;
    top: calc(50% - 10px);
  }

  .recom_charge {
    justify-content: flex-start;
  }

  .recom_charge .bubble {
    margin: auto 0;
    background-color: #ebf1f9;
    border: 1px solid #004f9d;
  }

  .recom_charge .bubble:after {
    border-right: 10px solid #004f9d;
    left: -10px;
    top: calc(50% - 10px);
  }
}

.load-more-recom{
  text-align: center;
  .load-more-line {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.55);
    margin: 20px 0;
  }
  button{
    span{
      color: #004f9d;
    }
  }
}

/*---------------------------------------------------------------------------------
  よくある質問
---------------------------------------------------------------------------------*/
.detail-faq{
  padding: 3rem 1.5rem;
  margin-bottom: -1rem;
}

.plan-faq{
  padding-top: 9rem;
  margin-top: -11rem;
}

/*---------------------------------------------------------------------------------
  寺院詳細ページGoogleMap
---------------------------------------------------------------------------------*/
.detail-cnt-map > iframe{
  width: 100%;
  height: 50vh
}