@use "../_global/var" as *;
@use "../_global/mixins" as *;
.modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgb(255 255 255 / 93%);
  &-content {
    //width: 650px;
    width: 100%;
    max-width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: modalopen;
    animation-duration: 1s;
    border: 1px solid $main;
  }
  &-header {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background-color: white;
    font-size: 20px;
  }
  &-close {
    display: flex;
    align-items: stretch;
    font-size: 1rem;
    .triangle {
      width: 0px;
      height: 0;
      border-style: solid;
      border-width: 21px 18px 21px 0;
      border-color: transparent $main transparent transparent;
      transform: rotate(0deg);
    }
    .inr {
      background-color: $main;
      color: white;
      line-height: 1;
      padding: 1rem;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &-cnt {
    padding: 1rem;
    color: $main;
    &.over-scroll {
      max-height: calc(100vh / 1.3);
      overflow: scroll;
      overscroll-behavior: contain;
    }
  }
  .check-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    &-item {
    }
  }
  &.result {
    .modal-content {
      width: 990px;
      border: none;
    }
    .modal-close {
      margin-top: 2rem;
      position: relative;
      .icon {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: 1.5rem;
        line-height: 1;
      }
      .btn {
        margin: auto;
      }
    }
  }
}
@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.form-search {
  &-item {
    margin-bottom: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }
    .ttl {
      background-color: white;
      font-size: 16px;
      padding: 0.5rem;
      .area-select-cnt & {
        text-align: inherit;
        font-size: 16px;
        letter-spacing: inherit;
        margin-bottom: initial;
      }
    }
    .check-group {
      background-color: $gray;
      padding: 1rem;
    }
  }
  .search-result {
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    @include mq(sp) {
      flex-wrap: wrap;
      justify-content: center;
    }
    &-txt {
      font-size: 16px;
      .num {
        font-size: 32px;
      }
    }
  }
}

.modal-keyword {
  width: 100%;
  background-color: #ebf1f9;
  padding: 1rem;


  &-box {
    padding: 0.5rem;
    border: 1px solid #004f9d;
    border-radius: 4px;
    background-color: white;
    display: flex;
    align-items: center;
  }

  &-icon {
    width: 20px;
    height: 20px;
    margin-right: .5rem;
  }
}

#simulation-complete {
  .form-cnt {
    color: $black;
  }
}

.detail-search-toggle {
  .check-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
}
@include mq('phone-sm-middle-xl') {
  .detail-search-toggle {
    .ttl {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:after {
        content: '+';
        margin-left: auto;
        font-weight: bold;
        font-size: 25px;
        width: 40px;
        height: 40px;
        line-height: 38px;
        vertical-align: middle;
        padding: 0;
        border-radius: 50%;
        background-color: $main;
        color: white;
        text-align: center;
      }

      &.active:after {
        content: '-';
        margin-left: auto;
        font-weight: bold;
        width: 40px;
        height: 40px;
        line-height: 35px;
        padding: 0;
        vertical-align: middle;
        border-radius: 50%;
        background-color: $main;
        color: white;
        text-align: center;
      }
    }
  }
}
@include mq('phone-sm-middle-xl') {
  .detail-modal-fixed {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: rgba($main, 0.9);
  }
  .modal-cnt-fixed {
    padding-bottom: 120px;
  }
  .btn-radius-fixed {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}



