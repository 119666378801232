@use "../_global/var" as *;
@use "../_global/mixins" as *;
// *********** accordion ***********
.accordion {
    transition: all .6s;
    overflow: hidden;
    &.on {
        height: 0;
    }
    &-btn {
        cursor: pointer;
        &.icon.on {
            transform: scale(1, -1);
        }
    }
    .inr {
        border: 1px solid $main;
        &:not(:first-child) {
            border-top: none;
        }
        .question {
            display: flex;
            align-items: center;
            padding: 1rem;
            cursor: pointer;
            &:hover {
                background-color: $blue-l;
            }
            .q {
                flex-shrink: 0;
                color: $main;
                font-size: 1.75rem;
            }
            .ttl {
                flex-grow: 1;
                font-weight: bold;
                font-size: 16px;
                margin: 0 1rem;
                line-height: 1.4;
            }
            .i {
                flex-shrink: 0;
            }
            &.active {
                background-color: $blue-l;
                .i {
                    .line {
                        &:last-child {
                            transform: translate(-50%, -1000%) rotate(90deg);
                        }
                    }
                }
            }
        }
        .answer {
            padding: 1rem 1rem 1rem 2rem;
            display: none;
            &.on {
                display: block;
            }
            .a {
                color: $main;
                font-size: 1.75rem;
                margin-right: 1rem;
                margin-top: -0.5rem
            }
            .wrap {
                display: flex;
            }
        }
    }
}
.accordion2 {
    &-trig {
        display: flex;
        &-icon {
            margin-left: 1rem;
            cursor: pointer;
            &.on {
                .line {
                    &:last-child {
                        transform: translate(-50%, -1000%) rotate(90deg);
                    }
                }
            }
        }
    }
    &-cnt {
        display: none;
    }
}
