@use "../_global/var" as *;
@use "../_global/mixins" as *;
// ****** cta *******
.cta {
    background-color: $bg-y;
    border-radius: 4px;
}
.cta1 {
    width: 100%;
    max-width: 710px;
    &-cnt {
        padding: 1.25rem;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .cnt-l {
            flex-shrink: 0;
            .txt {
                font-weight: 500;
                font-size: 18px;
                width: max-content;
                white-space: nowrap;
                .time {
                    display: block;
                    font-size: 12px;
                    line-height: 1;
                }
            }
        }
        .cnt-c {
            width: 2px;
            height: 3rem;
            margin: 0 1rem;
            background-color: $black;
            border-radius: 1px;
        }
        .cnt-r {
            flex-shrink: 0;
        }
    }
}

/*---------------------------------------------------------------------------------

---------------------------------------------------------------------------------*/

.cta1-new {
    width: 100%;
    max-width: 710px;
    &-cnt {
        padding: 1.25rem;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content-section-l {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .txt {
                text-align: right;
                white-space: nowrap;
                font-weight: 500;
                font-size: 8px;
            }
        }
        .content-section-l.width {
            width: 50%;
        }
        .content-section-r {
            margin-left: 20px;
                .txt {
                    font-weight: 500;
                    font-size: 15px;
                    width: max-content;
                    white-space: nowrap;
                    margin-bottom: 25px;
                    .time {
                        display: block;
                        font-size: 10px;
                        line-height: 1;
                    }
                }
        }
        .content-section-r.width {
            width: 50%;
        }
        .cnt-c {
            width: 2px;
            height: 3rem;
            margin: 0 1rem;
            background-color: $black;
            border-radius: 1px;
        }
        .cnt-r {
            transform: scale(0.9);
        }
    }
}
/*---------------------------------------------------------------------------------

---------------------------------------------------------------------------------*/
.cta2 {
    padding: 1.5rem;
    text-align: center;
    font-weight: bold;
    &-txt {
        font-size: 16px;
    }
    .free-num2 {
        margin: 4px auto;
        .cnt-r {
        }
    }
    @include mq(sp) {
        padding: 0.5rem;
        .free-num2 {
            max-width: 90%;
        }
    }
}
.cta2-new {
    padding: 1.5rem;
    text-align: center;
    font-weight: bold;
    &-no1-sec {
        margin-bottom: 12px;
        font-size: 10px;
        text-align: right;
        margin-right: 35px;
        @include mq(phone-medium) {
            text-align: right;
            margin-right: 35px;
            font-size: 8px;
        }
        @include mq-max(420px) {
            text-align: right;
            margin-right: 10px;
            font-size: 7px;
        }
    }
    &-txt {
        font-size: 16px;
    }
    .free-num2 {
        margin: 4px auto;
        .cnt-r {
        }
    }
    @include mq(sp) {
        padding: 0.5rem;
        .free-num2 {
            max-width: 90%;
        }
    }
}
.cta2-side-new {
    padding: 1.5rem;
    text-align: center;
    font-weight: bold;
    &-no1-sec {
        margin-bottom: 12px;
        font-size: 7px;
        text-align: right;
        @include mq (sp){
            text-align: right;
            margin-right: 10px;
            font-size: 9px;
        }
        @include mq(phone-medium) {
            text-align: right;
            margin-right: 35px;
            font-size: 8px;
        }
        @include mq-max(420px) {
            text-align: right;
            margin-right: 10px;
            font-size: 7px;
        }
    }
    &-txt {
        font-size: 16px;
    }
    .free-num2 {
        margin: 4px auto;
        .cnt-r {
        }
    }
    @include mq(sp) {
        padding: 0.5rem;
        .free-num2 {
            max-width: 90%;
        }
    }
}



.cta3 {
    .img {
        width: 272px;
        margin: auto;
    }
    .request {
        padding: 1rem;
        margin-top: 1rem;
        justify-content: space-between;
        background-color: $bg-y;
        .item {
            font-size: 16px;
            justify-content: center;
        }
    }
    .icon-txt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
    }
    @include mq(sp) {
        a.link {
            font-size: 12px;
            padding: .5rem 0.25rem;
            line-height: 1.4;
            .icon {
                width: 20px;
            }
        }
    }
}
.cta3-2 {
    .img {
        width: auto;
        margin: auto;
        margin-top: 3rem;
    }
    .request-cnt {
        margin-top: 1.5rem;
    }
}

.cta5 {
    padding: 1.5rem;
    &-cnt {
        display: flex;
        align-items: center;
        gap: 1rem;
        .img {
            width: 108px;
            flex-shrink: 0;
        }
        .txt {
            flex-grow: 1;
            transition: all 0.4s;
            text-decoration: underline;
        }
        .icon {
            transition: all 0.4s;
        }
    }
    .link {
        &:hover {
            .txt {
                color: $blue;
            }
            .icon {
                path {
                    fill: $blue;
                }
            }
        }
    }
}

.cta6 {
    padding: 1.5rem;
    background-color: #F9F9F9;
    &-cnt {
        display: flex;
        align-items: center;
        gap: 1rem;
        .img {
            width: 108px;
            flex-shrink: 0;
        }
        .txt {
            flex-grow: 1;
            transition: all 0.4s;
            text-decoration: underline;
        }
        .icon {
            transition: all 0.4s;
        }
    }
    .link {
        color: #0038EF;
        &:hover {
            .txt {
                color: $blue;
            }
            .icon {
                path {
                    fill: $blue;
                }
            }
        }
    }
}
.cta7 {
    border: solid 4px #23A3FA;
    &_title {
        font-size: 22px;
        background-color: #23A3FA;
        display: block;
        text-align: center;
        color: white;
        padding: 2px 0rem;
        @include mq-max(950px) {
            font-size: 18px;
            padding: 8px 20px;
        }
    }
    &_body {
        padding: 20px;
        @include mq-max(950px) {
            padding: 30px 20px 20px;
        }
        .cta7_button {
            font-size: 23px;
            display: block;
            text-align: center;
            color: white;
            padding: 3px 0rem;
            border-radius: 7px;
            background: linear-gradient(360deg, #df0914 0%, #e70713 8.33%, #f68990 100%);
            position: relative;
            z-index: 1;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                transition: all 0.4s;
                width: 100%;
                height: 100%;
                border-radius: 7px;
                background: linear-gradient(180deg, #df0914 0%, #e70713 8.33%, #f68990 100%);
            }
            &:hover {
                &::before {
                    opacity: 0;
                }
            }
            span {
                z-index: 3;
                position: relative;
            }
        }
        .sub_text {
            text-align: center;
            margin-bottom: 3px;
            span {
                position: relative;
                &::before, &::after {
                    position: absolute;
                    top: 0rem;
                    height: 1.5rem;
                    content: '';
                }
                &::before {
                    border-left: solid 2px;
                    left: -9px;
                    transform: rotate(-30deg);
                }
                &::after {
                    border-right: solid 2px;
                    right: -9px;
                    transform: rotate(30deg);
                }
            }
        }
    }
    &_main {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        align-items: center;
        @include mq-max(950px) {
            flex-wrap: wrap-reverse;
        }
        &_left {
            width: 57%;
            @include mq-max(950px) {
                width: 85%;
                margin: 15px auto 10px;
            }
            @include mq-max(640px) {
                width: 100%;
            }
            &_list {
                font-size: 13px;
                @include mq-max(950px) {
                    margin-top: 10px;
                }
            }
            li {
                list-style-type: none;
                position: relative;
                padding-left: 0.6em;
                line-height: 1.4;
                margin: 5px 0;
                color: #393939;
                font-weight: 500;
                &::before {
                    border-radius: 50%;
                    width: 4px;
                    height: 4px;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0.5rem;
                    content: "";
                    background: #23A3FA;
                }
            }
            .cta7-txt {
                margin-left: 0;
                max-width: 100%;
                font-size: 12px;
                @include mq-max(950px) {
                    max-width: 100%;
                }
            }
        }
        &_right {
            width: 38%;
            @include mq-max(950px) {
                width: 100%;
            }
        }
    }
    img {
        width: 100%;
        @include mq-max(950px) {
            width: 85%;
            margin: 0 auto;
        }
        @include mq-max(640px) {
            width: 100%;
        }
    }
}
