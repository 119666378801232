@use "../_global/var" as *;
@use "../_global/mixins" as *;
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem auto;
    column-gap: 0.75rem;
    &-item {
        .disabled {
            color: #d7d7d7;
            svg {
                path {
                    fill: #d7d7d7;
                }
            }
        }
        .link {
            width: 32px;
            height: 32px;
            line-height: 32px;
            border-radius: 50%;
            display: block;
            text-align: center;
            background-color: #d7d7d7;
            color: white;
            &:hover {
                background-color: darken($main, 10%);
            }
            &.space {
                background-color: transparent;
                color: $black;
            }
        }
        .current {
            background-color: $main;
            color: white;
        }
        .next {
            display: flex;
            align-items: center;
            column-gap: 0.25rem;
            margin-left: 1rem;
            &:hover {
                color: $main;
                svg {
                    path {
                        fill: $main;
                    }
                }
            }
            &.disabled {
                &:hover {
                    color: #d7d7d7;
                    svg {
                        path {
                            fill: #d7d7d7;
                        }
                    }
                }
            }
        }
        .prev {
            display: flex;
            align-items: center;
            column-gap: 0.25rem;
            margin-right: 1rem;
            &:hover {
                color: $main;
                svg {
                    path {
                        fill: $main;
                    }
                }
            }
            &.disabled {
                &:hover {
                    color: #d7d7d7;
                    svg {
                        path {
                            fill: #d7d7d7;
                        }
                    }
                }
            }
        }
    }
}