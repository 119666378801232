@use "../_global/_var" as *;
@use "../_global/_mixins" as *;

.temple-cnt.wp-layout {
  font-weight: 500;
  h2 {
    color: #004f9d;
    font-weight: bold;
    font-size: 23px;
    margin: 1.5rem auto;
  }
  h3 {
    color: #004f9d;
    font-size: 18px;
    margin: .5rem 1rem;
    font-weight: bold;
  }
  h4 {
    color: #004f9d;
    font-size: 15px;
    margin: .5rem 1.3rem;
    font-weight: bold;
  }
  p {
    margin: 1rem auto;
  }
  strong, em {
    font-weight: bold;
  }
  a:not([class]) {
    display: inline;
    text-decoration: underline;
    color: $blue2;
  }
  ul:not([class]) {
    list-style: disc;
    margin-left: 2rem;
  }
  blockquote {
    color: lighten($black, 35%);
    border-left: 3px solid lighten($black, 45%);
    padding: .5rem 0 .5rem 3%;
    margin: 1rem 0;
    p {
      margin: 0;
    }
  }
  .wp-block-table {
    margin: 1.5rem 0;
    thead {
      border-bottom: 3px solid lighten($black, 20%);
    }
    th, td {
      border: 1px solid lighten($black, 20%);
    }
    th {
      background-color: $gray;
    }
  }
}

.magazine-cnt.wp-layout {
  font-weight: 500;
  strong, em {
    font-weight: bold;
  }
  a:not([class]) {
    display: inline;
    text-decoration: underline;
    color: $blue2;
  }
  ul:not([class]) {
    list-style: disc;
    margin-left: 2rem;
  }
  blockquote {
    color: lighten($black, 35%);
    border-left: 3px solid lighten($black, 45%);
    padding: .5rem 0 .5rem 3%;
    margin: 1rem 0;
    p {
      margin: 0;
    }
  }
  .wp-block-table {
    margin: 1.5rem 0;
    thead {
      border-bottom: 3px solid lighten($black, 20%);
    }
    th, td {
      border: 1px solid lighten($black, 20%);
    }
    th {
      background-color: $gray;
    }
  }
}
