@use "../_global/var" as *;
@use "../_global/mixins" as *;
.slider {
    &-for {
        margin-bottom: 0.25rem;
        .img {
            position: relative;
            aspect-ratio: 16 / 9;
            img {
                object-fit: cover;
                height: 100%;
            }
            .caption-wrap {
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 0;
                width: 100%;
                color: white;
                .count {
                    margin: 2%;
                    border-radius: 2rem;
                    background-color: #00000080;
                    display: inline-block;
                    padding: 0.25rem 1rem;
                }
                .caption {
                    background-color: $main;
                    padding: 0.25rem .5rem;
                }
            }
            .caption-wrap-modified {
                transform: translateY(-55px);
                position: static;
                @include mq(phone-medium){
                    transform: translateY(-45px);
                }
            }
        }
    }
    .slider-for-modified{
        margin-bottom: -3.75rem;
        @include mq(phone-medium){
            margin-bottom: -2.75rem;
        }
        @include mq(phone-small){
            margin-bottom: -2.5rem;
        }

    }
    &-movie{
        position: relative;
        width: 100%;
        //padding-top: 56.25%; /* 16:9のアスペクト比 */
        aspect-ratio: 16 / 9;
        background-color: black;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .caption-wrap-movie {
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            color: white;
            .count {
                margin: 2%;
                border-radius: 2rem;
                background-color: #00000080;
                display: inline-block;
                padding: 0.25rem 1rem;
            }
            .caption {
                background-color: $main;
                padding: 0.25rem .5rem;
            }
        }
        .caption-wrap-movie-modified {
            transform: translateY(35px);
            @include mq(phone-medium){
                transform: translateY(40px);
            }
        }
    }

    &-nav {
        .img {
            position: relative;
            aspect-ratio: 16 / 9;
            img {
                object-fit: cover;
                height: 100%;
            }
        }
        .slick-current {
            opacity: 0.5;
        }
        .slick-slide {
            margin-right: 0.5rem !important;
            width: 108px;
        }
    }
}


.slider-article {
    margin: 1rem auto 0;
    max-width: 520px;
    &-item {
        width: 204px !important;
        margin: .5rem;
    }
    .slick-next {
        right: -39px;
    }
    .slick-prev {
        left: -40px;
    }
}