@use "_var" as *; // setting files
@use "mixins" as *;

// *********** color *************
.main {
  color: $main;
}
// *********** size *************
.small {
  font-size: 12px;
}

// *********** align *************
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.vertical {
  writing-mode: vertical-rl;
}

// *********** text *************
.txt-normal {
  font-weight: normal;
}
.label-txt {
  padding: .25rem 0.5rem;
  line-height: 1;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 4px;
  background-color: $main;
  color: white;
  &.yellow {
    background-color: $yellow;
    color: white;
  }
  &.red {
    background-color: $red;
    color: white;
    margin-right: 3px
  }
  &.tp {
    background-color: transparent;
    color: $main;
    border: 1px solid $main;
  }
  &2 {
    padding: .25rem 0.5rem;
    line-height: 1;
    font-size: 12px;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 4px;
    background-color: white;
    color: $main;
    border: 1px solid;
  }
}

.label-txt-round {
  border-radius: 10rem;
  color: #ccc;
  background-color: #f9f9f9;
  line-height: 1;
  padding: 0.75rem 1rem;
  display: block;
  text-align: center;
  &.active {
    background: #ebf1f9;
    color: $main;
  }
  @include mq(sp) {
    padding: 0.5rem;
  }
}

.label-txt-square {
  color: $main;
  background-color: white;
  line-height: 1;
  padding: 0.5rem 1rem;
  display: block;
  text-align: center;
  border: 1px solid;
  &.active {
    background: #ebf1f9;
    color: $main;
  }
}

.icon-txt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  .icon {
    width: 1.125em;
    line-height: 1;
  }
}

.text {
  &-link {
    display: inline;
    text-decoration: underline;
    color: $blue2;
  }
}

.form-label-set {
  display: flex;
  align-items: center;
  &-item {
    margin-left: 1rem;
    margin: 1rem auto;
    &:first-child {
      min-width: 6rem;
    }
    &:last-child {
      flex-grow: 1;
    }
    select {
      width: 100%;
    }
  }
}
// ****** cta *******
.free-num {
  background: $gradOrange;
  color: white;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 0.4rem;
  border-radius: 6px;
  font-size: 12px;
  span {
    display: block;
  }
  .num-wrap {
    display: flex;
  }
  .num-l {
    flex-shrink: 0;
    width: 9px;
    margin-right: 0.125rem;
  }
  .num-r {
    font-family: $en-font;
    font-size: 1rem;
  }
}

.free-num2 {
  background: $gradOrange;
  color: white;
  font-family: $en-font;
  border-radius: 25px;
  box-shadow: 0px 6px 4px rgba(139, 48, 0, 0.25);
  display: flex;
  align-items: center;
  transition: all .4s;
  .cnt-l {
    border-radius: 25px 0px 0px 25px;
    padding: 15px 11px 12px 19px;
    background: $gradGray;
  }
  .cnt-r {
    font-size: 2rem;
    line-height: 1;
    flex-grow: 1;
    .num {
      padding: 5px 12px 5px 7px;
      font-weight: bold;
    }
  }
  &:hover {
    transform: scale(1.025);
    box-shadow: 0px 6px 9px rgba(139, 48, 0, 0.25);
  }
}

.free-num3 {
  background: $gradOrange;
  color: white;
  font-family: $en-font;
  border-radius: 25px;
  box-shadow: 0px 6px 4px rgba(139, 48, 0, 0.25);
  display: flex;
  align-items: center;
  transition: all .4s;
  transform: scale(0.8);
  .cnt-l-2 {
    border-radius: 25px 0px 0px 25px;
    padding: 15px 11px 12px 19px;
    background: $gradGray;
  }
  .cnt-r-2 {
    font-size: 2rem;
    line-height: 1;
    flex-grow: 1;
    .num {
      padding: 5px 12px 5px 8px;
      font-weight: bold;
    }
  }
  &:hover {
    transform: scale(1.025);
    box-shadow: 0px 6px 9px rgba(139, 48, 0, 0.25);
  }
}


.txt-free {
  display: inline-block;
  background-color: $red;
  color: white;
  padding: 6px 10px;
  border-radius: 6px;
  margin-left: 0.125rem;
  margin-bottom: 0.125rem;
  line-height: 1;
}


// *********** box *************
.inline-radius {
  display: inline-block;
  line-height: 1;
  padding: 0.5rem 0.75rem;
  color: $main;
  border: 1px solid $main;
  border-radius: 30px;
  background-color: white;
  width: max-content;
  text-transform: uppercase;
  font-weight: 500;
  &.r4p {
    border-radius: 4px;
  }
}

// ********** css icon ************
.icon {
  &.circle {
    border-radius: 50%;
    border: 1px solid $main;
  }
}
.icon-circle-plus {
  width: 2rem;
  height: 2rem;
  background-color: $main;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
  .line {
    background-color: white;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all .6s;
    &:first-child {
      width: 40%;
      height: 2px;
    }
    &:last-child {
      width: 2px;
      height: 40%;
    }
  }
}

// ********** title ************
.title {
  color: $main;
  font-weight: bold;
  font-size: 23px;
  &2 {
    font-size: 18px;
    &.main {
      color: $main;
    }
  }
}

.ttl {
  font-size: 23px;
  @include mq(phone-medium) {
    font-size: 20px;
  }
  &-sub {
    font-size: 20px;
  }
  &-normal {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.ttl-round-arrow-d {
  font-size: 1rem;
  span {
    display: inline-block;
    padding: 0.5rem 1.25rem;
    text-align: center;
    border-radius: 3rem;
    position: relative;
    z-index: 1;
    &.yellow {
      color: white;
      background-color: $yellow;
    }
    &.main {
      color: white;
      background-color: $main;
      &::after {
        border-color: $main transparent transparent transparent;
      }
    }
    &.red {
      background-color: $red;
      &::after {
        border-color: $red transparent transparent transparent;
      }
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: -18%;
      left: 50%;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 24px 20px 0 20px;
      border-color: $yellow transparent transparent transparent;
      transform: translateX(-50%) rotate(0deg);
    }
  }
}

.ttl-border-lr {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: normal;
  .inr {
    display: flex;
    justify-content: center;
    align-items: stretch;
    .line {
      width: 1px;
      margin: 2px;
      display: block;
      background-color: $black;
    }
    .txt {
      margin: 0 1rem;
    }
  }
  &.white {
    color: white;
    .inr {
      .line {
        background-color: white;
      }
    }
  }
}

.ttl-border-l {
  color: $main;
  line-height: 1.4;
  padding-left: 1rem;
  border-left: 3px solid $main;
  font-weight: 600;
}

.ttl-border-l2 {
  color: #004f9d;
  line-height: 1.4;
  padding-left: 1rem;
  border-left: 3px solid #004f9d;
;
  font-weight: 600;
}


.title-icon {
  display: flex;
  align-items: center;
  color: $main;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid;
  column-gap: 1rem;
  font-size: 18px;
  margin-bottom: 1rem;
  .icon {
    line-height: 1;
  }
}


.page-ttl {
  padding: 0 1.5rem;
  margin: 3rem auto 1rem;
  font-size: 26px;
  color: $main;
  .label-txt {
    font-size: 0.8em;
  }
  .ruby {
    font-size: 16px;
    font-weight: bold;
    color: $black;
  }
  .desc {
    font-size: 16px;
    color: $black;
    margin-top: 1rem;
  }
  .cat {
    font-size: 16px;
    color: $black;
  }
  &.modified-margin {
    margin: 0rem auto 0rem;
  }
}


.page-ttl2 {
  text-align: center;
  margin: 3rem auto 1rem;
  .ttl {
    font-size: 26px;
    color: $main;
  }
  .copy {
    font-size: 16px;
  }
}

.error-txt {
  color: #E65454;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  .input-box & {
    padding-left: .5rem;
  }
}
