@use "../_global/var" as *;
@use "../_global/mixins" as *;

// *********** keyvisual *************
.kv {
  background: rgb(238 238 238 / 17%);
  position: relative;
  &-inr {
    position: relative;
    z-index: 1;
    margin-bottom: -11rem;
    .mask {
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: 50% 100%;
      mask-image: url(../img/kv/kv_mask.svg);
      position: relative;
      z-index: 1;
      height: 24rem;
    }
    .mask-bg {
      position: absolute;
      z-index: 0;
      bottom: 12px;
      left: 0;
      width: 100%;
    }
  }
  &-img1 {
    position: absolute;
    bottom: 40px;
    right: 23rem;
    z-index: 2;
    width: 14rem;
  }
  &-img2 {
    position: absolute;
    bottom: 13.5rem;
    left: 14rem;
    z-index: 2;
    width: 18rem;
  }
  &-img3 {
    position: absolute;
    bottom: 9rem;
    right: 12.5rem;
    z-index: 1;
    width: 15rem;
  }
  .cta {
    margin: -4rem auto;
    position: relative;
    z-index: 9;
    @include mq(sp) {
      margin-top: -9rem; /* ここを変更 */
    }
    @include mq(phone-medium) {
      margin-top: -7rem; /* ここを変更 */
    }
    @include mq(phone-sm-middle-xl) {
      margin-top: -6rem; /* ここを変更 */
    }
  }
  @include mq(sp) {
    &-inr {
      margin-bottom: -38rem;
    }
  }
}

.top-main {
  background-color: white;
  margin-bottom: 4rem;
  &-cnt {
    // margin-bottom: 3.75rem;
    // &:last-child {
    //     margin-bottom: -3rem;
    // }
    &.new {
      .calender {
        margin-bottom: 0;
      }
    }
    &.feature {
      padding-top: 3rem;
    }
  }
  .plan {
    margin-top: 3rem;
  }
  @include mq(sp) {
    margin-bottom: 2rem;
    &-cnt {
      &.worry {
        margin-top: 4rem;
        width: 100%;
      }
    }
  }
}

// *********** feature ***********
.feature {
  &-cnt {
    strong {
      background-color: $yellow-l;
    }
    .txt {
      margin-bottom: 1rem;
    }
  }
  &-anchors {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 1rem auto;
    .item {
      width: 128px;
      margin: 1rem;
    }
    .img {
      aspect-ratio: 1/1;
      margin-bottom: -1rem;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    .link {
      background-color: white;
      color: $main;
      border: 1px solid $main;
      font-size: 10px;
      font-weight: bold;
      display: inline-block;
      line-height: 1;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      svg {
        path {
          transition: all 0.4s;
        }
      }
      &:hover {
        background-color: $main;
        color: white;
        svg {
          path {
            stroke: white;
          }
        }
      }
    }
  }

  &-sec {
    margin: 1.25rem auto;
    .inr {
      border: 2px solid $main;
    }
    .fs-ttl {
      font-size: 1.125rem;
      font-weight: bold;
      background-image: url(../img/bg-main.svg);
      background-color: rgba(235,241,249,.92);
      background-blend-mode: lighten;
      background-size: 1rem;
      text-align: center;
      color: $main;
      padding: 1.5rem 0;
      line-height: 1.4;
    }
    .fs-txt {
      padding: 1rem;
      font-weight: 500;
      line-height: 1.8;
      a {
        color: white;
        font-weight: 500;
        text-align: center;
        margin-top: 1rem;
      }
    }
  }
  &-anchors {
    .item {
      margin: 0;
    }
  }
}

// *********** search ***********
.search-box {
  &.location {
    // margin-top: -3rem;
    padding-top: 12rem;
    @include mq(sp) {
      padding-top: 40rem;
    }
    .cnt {
      padding: 1rem 0 2rem;
    }
  }
  .cnt {
    position: relative;
    z-index: 3;
    color: white;
    .ttl-border-lr {
      .inr {
        .line {
          background-color: white;
        }
      }
    }
  }
  &.tomb {
    background-color: rgb(235 241 249 / 58%);
    color: $black;
    .cnt {
      .ttl-border-lr {
        .inr {
          .line {
            background-color: $black;
          }
        }
      }
    }
    .list {
      .item {
        .link {
          background-color: $main;
          color: white;
        }
      }
    }
  }
  @include mq(sp) {
  }
}

.search-keyword {
  background-color: rgb(239 239 239 / 40%);
  padding: 30px 140px;
  @include mq(sp) {
    padding: 1.5rem 0;
  }
}

.search-type {
  @extend .search-box;
  background-color: rgb(235 241 249 / 58%);
  color: $black;
  .cnt {
    .ttl-border-lr {
      color: $black;
      .inr {
        .line {
          background-color: $black;
        }
      }
    }
  }
  .list {
    .item {
      .link {
        background-color: $main;
        color: white;
      }
    }
  }
}

.search-form {
  .s-wrap {
    display: flex;
    align-items: stretch;
  }
  .s-box {
    padding: 0.5rem 0.75rem;
    border: 2px solid #cccccc;
    border-top-left-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-right: none;
    background-color: white;
  }
  .s-icon {
    background-color: $main;
    height: 100%;
    padding: 0.5rem;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .popular-word {
    .ttl {
      margin: 0.75rem auto 0.5rem;
      font-size: 16px;
    }
    .word-list {
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        a {
          font-weight: 500;
          &:hover {
            background-color: $main;
            color: white;
          }
        }
      }
    }
  }
}

// *********** plan ***********
.plan-cnt {
  margin: 2rem auto;
}

.plan-card {
  margin: -9px 0;
}

// *********** review ***********
.review {
  &-box {
    .list {
      margin: 2rem auto;
    }
  }
}

// *********** faq ***********
.faq {
  &-box {
    .list {
      margin: 2rem auto;
    }
  }
}

// *********** worry ***********
.worry {
  background-color: white;
  &-first {
    .ttl-box {
      padding: 3.5rem;
      text-align: center;
      width: 90%;
      margin: -32% auto 2.5rem;
      box-shadow: 4px 4px 14px rgba(5, 39, 78, 0.3);
      .ttl {
        margin-bottom: 2rem;
        font-size: 1.75rem;
      }
      .txt {
        font-size: 1rem;
        line-height: 2;
        font-weight: 500;
        letter-spacing: 1px;
      }
      .bg-img {
        background-size: 2rem;
        opacity: 0.08;
      }
    }
  }
  @include mq(sp) {
    &-first {
      .ttl-box {
        padding: 7% 5%;
        .ttl {
          font-size: 28px;
          line-height: 1.6;
        }
      }
    }
  }
}
.fundamental {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.27rem;
    .hr {
      width: 1px;
      height: auto;
      background-color: $gray;
    }
  }
  &-item {
    flex-basis: 29.9%;
    .link {
      &:hover {
        .ttl {
          text-decoration: none;
        }
      }
      .ttl {
        color: $main;
        font-weight: bold;
        text-decoration: underline;
        font-size: 1rem;
      }
      .meta {
        margin: 0.75rem auto 0rem;
        .tag {
          background-color: $main;
          color: white;
          padding: 0.25rem 0.5rem;
          text-transform: uppercase;
          margin-left: 0.25rem;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .more {
    text-align: right;
    .link {
      background-color: $main;
      color: white;
      display: inline-block;
      padding: 0.25rem 1rem;
      min-width: auto;
      &:hover {
        background-color: darken($main, 10%);
        color: white;
      }
    }
  }
  @include mq(sp) {
    margin: 0 5%;
    &-list {
      display: block;
      .hr {
        margin: 5% auto;
        height: 1px;
        width: 100%;
      }
      .link {
        display: flex;
        gap: 1rem;
        .thumb {
          aspect-ratio: 1/1;
          width: 7rem;
          flex-shrink: 0;
        }
      }
    }
    &-item {
      &.magazine-item {
        .link {
          align-items: center;
          flex-wrap: wrap;
          gap: inherit;
          .thumb {
            aspect-ratio: auto;
            width: 100%;
            flex-shrink: inherit;
          }
        }
      }
    }
  }
}

.news {
  .list {
    margin: 1.25rem auto;
    .item {
      padding-bottom: 1.25rem;
      border-bottom: 1px solid #bfbfbf;
      margin-bottom: 1.25rem;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .link {
        &:hover {
          background-color: white;
        }
      }
    }
  }
  .more {
    text-align: right;
    .link {
      background-color: $main;
      color: white;
      display: inline-block;
      padding: 0.25rem 1rem;
      &:hover {
        background-color: darken($main, 10%);
        color: white;
      }
    }
  }
}