@use "../_global/var" as *;
@use "../_global/mixins" as *;

.fix-header {
  background-color: white;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  box-shadow: 1px 4px 28px -19px #0000004d;
  width: 100%;
}
.fix-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  transform: translateY(-104%);
  transition: transform .6s;
  display: none;
  .menu-cnt {
    background-color: $main;
    position: relative;
    &.on {
      transform: translateY(59.5px);
    }
    &::after {
      content: "";
      background-image: url(../img/bg-white.svg);
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.15;
      background-size: 2rem;
    }
  }
  .menu-inr {
    max-width: 990px;
    margin: auto;
    padding: 2% 0;
    position: relative;
    z-index: 2;
    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .item {
        //flex-basis: calc((100% - 2rem) / 3);
        flex-basis: calc((100% - 2rem) / 2);
        .btn-round {
          text-align: left;
          font-size: 16px;
        }
        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1rem;
          svg {
            width: 1.125em;
            display: block;
            height: 1.125em;
          }
          path {
            fill: $main;
          }
        }
      }
    }
  }
  .menu-close {
    text-align: center;
    margin-top: 1.5rem;
    position: relative;
    z-index: 2;
    .close {
      display: inline-block;
      background-color: white;
      border: 1px solid $main;
      line-height: 1;
      border-radius: 10rem;
      padding: .75rem 2rem;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
    }
  }
  @include mq(sp) {
    .menu-inr {
      .list {
        padding: 1.5rem;
        .item {
          flex-basis: 100%;
        }
      }
    }
  }
}

.header {
  &-cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: white;
  }
  &-logo {
    font-size: 13px;
    font-weight: 500;
    .link {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      span {
        font-size: 13px;
        vertical-align: middle;
        line-height: 1;
        & .t2 {
          font-size: 18px;
        }
      }
      img {
        width: 2.5rem;
        margin-right: 0.5rem;
        flex-shrink: 0;
        @include mq(sp) {
          width: 3.25rem;
        }
        @include mq-max(380px) {
          width: 2rem;
        }
      }
    }
  }
  &-r {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  &-cta {
    font-size: 12px;
    padding: 0rem 1rem;
    background-color: $bg-y;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    .txt {
      line-height: 1;
      white-space: nowrap;
      span {
        background-color: $red;
        color: white;
        padding: 4px 10px;
        display: inline-block;
        border-radius: 6px;
        margin-left: 0.125rem;
        margin-bottom: 0.125rem;
      }
    }
  }
  &-menu {
    background-color: #eee;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: background-color .6s;
    width: 5rem;
    text-align: center;
    &:hover {
      background-color: darken(#eee,10%);
    }
    &.on {
      .menu-btn {
        &::after {
          content: "閉じる";
        }
        .icon {
          .top {
            transform: rotate(32deg);
            top: 50%;
            left: 0%;
            width: 108%;
          }
          .mid {
            transform: translateX(-150%) rotate(0);
          }
          .btm {
            transform: rotate(-32deg);
            top: 50%;
            left: 0%;
            width: 108%;
          }
        }
      }
    }
  }
  @include mq(sp) {
    &-cnt {
      .header-l {
        padding-left: 0.5rem;
      }
    }
    &-menu {
      padding: 8px;
    }
    &-cta {
      background: none;
      padding: 0 0.5rem;
      .txt {
        display: none;
      }
      .free-num {
        .cnt {
          span {
            font-size: 12px;
          }
          .num-r {
            font-size: 16px;
            @include mq(phone-sm-middle-m) {
              font-size: 14px;
            }
          }
        }
      }
    }
    &-logo {
      span {
        display: block;
      }
      .link {
        //img {
        //  width: 3.25rem;
        //}
        .t1 {
          font-size: 10px;
        }
        .t2 {
          line-height: 1;
          font-size: 18px;
        }
      }
    }
  }
}

.page-header {
  margin-bottom: 2rem;
  &-thumb {
    position: relative;
    z-index: 1;
    //aspect-ratio: 16/9;
    .img {
      height: 100%;
      object-fit: cover;
    }
  }
  &-txt {
    position: absolute;
    z-index: 2;
    bottom: -4rem;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
  }
  &-inr {
    width: calc(100% - 3rem);
    padding: 2.5rem;
    box-shadow: 4px 4px 14px rgb(5 39 78 / 30%);
    margin: auto;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    .txt {
      text-align: center;
      font-weight: 500;
      .ttl {
        color: $black;
        margin-bottom: 1rem;
        font-size: 28px;
      }
      .category-ttl {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: #004f9d;
        line-height: 1.4;
        font-weight: 600;
      }
    }
  }
  &-desc {
    padding: 10rem 1.5rem 1.5rem;
    font-weight: normal;
    @include mq-max(930px) {
      padding: 13rem 1.5rem 1.5rem;
    }
    @include mq-max(830px) {
      padding: 15rem 1.5rem 1.5rem;
    }
    @include mq-max(782px) {
      padding: 17rem 1.5rem 1.5rem;
    }
    @include mq(sp) {
      padding: 1.5rem;
    }
    @include mq-max(513px) {
      padding: 3rem 1.5rem 1.5rem;
    }
    @include mq-max(480px) {
      padding: 4rem 1.5rem 1.5rem;
    }
    @include mq-max(454px) {
      padding: 6rem 1.5rem 1.5rem;
    }
    @include mq-max(415px) {
      padding: 8rem 1.5rem 1.5rem;
    }
    p {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.news {
    margin-bottom: 10rem;
    @include mq(sp) {
      margin-bottom: 10%;
    }
    @include mq-max(475px) {
      margin-bottom: 17%;
    }
    @include mq-max(418px) {
      margin-bottom: 40%;
    }
    .page-header-inr {
      top: 16rem;
    }
  }
  &.magazine {
    .page-header-thumb {
      //aspect-ratio: 3/2;
      aspect-ratio: auto;
    }
    .page-header-inr {
      top: 21rem;
    }
  }
  .bg-img {
    background-size: 2rem;
  }
  @include mq(sp) {
    &.magazine {
      .page-header-inr {
        top: 9rem;
      }
    }
    &.news {
      .page-header-inr {
        top: 5rem;
      }
    }
  }
}

.search-temple {
  &-cnt {
    padding: 5rem 1.5rem 1.5rem;
    &.magazine {
      padding-top: 1.5rem;
    }
  }
  .bg-img {
    background-size: 2rem;
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: .93;
  z-index: 95;
}

.header-custom {
  padding: 17px;
}

.custom-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &-logo {
    width: 2.5rem;
    margin-right: 0.8rem;
    flex-shrink: 0;
    @include mq(sp) {
      width: 3.25rem;
    }
    @include mq-max(380px) {
      width: 2rem;
    }
  }

  .label {
    background-color: #004f9d;
    margin-right: .5rem;
    border-radius: 3px;
    padding: 1px 5px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    vertical-align: middle;
    @include mq-max('380px') {
      font-size: 12px;
    }
  }
  .line {
    margin-left: .5rem;
  }

  &-main-title {
    font-size: 16px;
    vertical-align: middle;
    color: #004f9d;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    @include mq(phone-sm-middle-s) {
      font-size: 14px;
    }
  }
}