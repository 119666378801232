@use "_var" as *;
@use "mixins" as *;

.dn {
  display: none;
}

.content {
  padding: 1.5rem;
}

.mt05 {
  margin-top: 0.5rem;
}

.mt2 {
  margin-top: 2rem;
}

// ********** container ***********
.thumb {
  .img {
    height: 100%;
    object-fit: cover;
  }
  &3-2 {
    aspect-ratio: 3/2;
    .img {
      height: 100%;
      object-fit: cover;
    }
  }
  &-square {
    aspect-ratio: 1/1;
    width: 90px;
    height: 90px;
  }
}

// ********** container ***********
.container {
  max-width: 990px;
  margin: auto;
  &-large {
    max-width: 1600px;
    margin: auto;
  }
  &-small {
    max-width: 710px;
    margin: auto;
  }
  @include mq(sp) {
    &-large {
      width: 90%;
    }
    &-small {
      width: 90%;
    }
  }
}


.request-download-container {
  max-width: 710px;
  margin: auto;
  padding: 1.5rem 0rem !important;
}




.section {
  margin: 5rem auto;
  &-small {
    margin: 3.5rem auto;
  }
  &-ss {
    margin: 1.25rem auto;
  }
  &-s3 {
    margin: 0.75rem auto;
  }
  &-large {
    margin: 10rem auto;
  }

  &-padding {
    padding: 3rem 0;
    &-small {
      padding: 1.5rem 0;
    }
  }
  @include mq(sp) {
    margin: 3rem auto;
    &-padding {
      padding: 5% 0;
    }
    &-small {
      margin: 2rem auto;
    }
  }
}
// ********** grid layout ***********
.container-grid {
  max-width: 990px;
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  .item {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    text-align: center;
  }
  @include mq(sp) {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(1, 5fr);
    grid-gap: 15px;
  }
}




// ********** flex layout ***********
.flex {
  display: flex;
  flex-wrap: wrap;
  .flex-item {
    min-width: auto;
  }
  &.gap1 {
    gap: 0.5rem;
  }
  &.gap2 {
    gap: 1rem;
  }
  &.gap3 {
    gap: 1.5rem;
  }
  &.ai-c {
    align-items: center;
  }
  &.c-c {
    align-items: center;
    justify-content: center;
  }
  &.col2 {
    &.gap2 {
      gap: 1rem;
      .flex-item {
        flex-basis: calc((100% - 1rem) / 2);
        a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &.gap3 {
      gap: 1.5rem;
      .flex-item {
        flex-basis: calc((100% - 1.5rem) / 2);
        a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &.padding1 {
      @include mq(sp) {
        padding: 2rem;
      }
    }
  }
  &.col3 {
    &.gap2 {
      gap: 0.5rem;
      .flex-item {
        flex-basis: calc((100% - 0.5rem * 2) / 3);
      }
    }
    &.gap3 {
      gap: 0.75rem;
      .flex-item {
        flex-basis: calc((100% - 0.75rem * 2) / 3);
      }
    }
  }
  &.btw {
    justify-content: space-between;
  }
  @include mq(sp) {
    &.col3 {
      &.gap3,&.gap2 {
        gap: 1rem;
        .flex-item {
          flex-basis: calc((100% - 1rem) / 2);
        }
      }
    }
  }
}

// ********** card layout ***********
// top plan section etc..
.card-layout {
  &-item {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    z-index: 2;
    // &::after {
    //     content: "";
    //     position: absolute;
    //     z-index: -1;
    //     width: 100%;
    //     height: 100%;
    // }
    .link {
      .thumb {
        aspect-ratio: 16/9;
        overflow: hidden;
        .img {
          height: 100%;
          object-fit: cover;
          transition: all 0.6s;
        }
      }
      &:hover {
        .thumb {
          .img {
            transform: scale(1.1);
          }
        }
        .ttl {
          background-color: darken($main, 10%);
        }
      }
      .ttl {
        background-color: $main;
        color: white;
        font-size: 1rem;
        text-align: center;
        transition: all 0.6s;
        span {
          font-size: 13px;
        }
      }
      .card-text {
        background-color: #ececec;
        color: #000000;
        font-size: 1rem;
        text-align: center;
        transition: all 0.6s;
        padding-bottom: 0.2rem;
        .ttl-top {
          line-height: 1.2;
          padding-top: 0.5rem;
        }
        .sub {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
    .link .thumb.no-aspect-ratio {
      aspect-ratio: auto;
    }
    &.shadow {
      box-shadow: 0px 5px 0px #283135;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          transform: translateY(1px);
          box-shadow: 0 0 0 #333;
        }
    }
  }
}

// top review section etc..
.card-layout2 {
  border: 1px solid $main;
  padding: 1rem;
  margin-bottom: 1rem;
  .c-header {
    display: flex;
    position: relative;
    z-index: 2;
    .icon-circle {
      width: 3.75rem;
      height: 3.75rem;
      margin-right: 0.75rem;
    }
    .tag {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      color: $main;
    }
  }
  .txt {
    font-weight: 500;
    position: relative;
    z-index: 2;
  }
}

.card-layout3 {
  padding: 0.5rem;
  background-color: white;
  color: $main;
  .thumb {
    aspect-ratio: 16/9;
    .img {
      height: 100%;
      object-fit: cover;
    }
  }
  .ttl {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .info {
    display: flex;
    gap: 0.5rem;
    font-size: 10px;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    .icon {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }
}

// top article etc..
.article-layout {
  .thumb {
    aspect-ratio: 3/2;
    overflow: hidden;
    .magazine-item & {
      aspect-ratio: auto;
    }
    .img {
      height: 100%;
      object-fit: cover;
    }
    .meta {
      display: flex;
      .tag {
      }
    }
  }
  &-row {
    display: flex;
    align-items: center;
    .thumb {
      $length: 5.625rem;
      width: $length;
      height: $length;
      aspect-ratio: 1/1;
      flex-shrink: 0;
      .img {
        height: 100%;
        object-fit: cover;
      }
    }
    .txt {
      flex-grow: 1;
      padding: 0 1rem;
      color: $main;
      font-weight: bold;
      svg {
        vertical-align: middle;
      }
      .meta {
        .time {
          color: $black;
        }
        .tag {
          padding: 0.25rem 0.5rem;
          background-color: $main;
          color: white;
          margin-left: 0.25rem;
        }
      }
      .ttl {
        text-decoration: underline;
        font-size: 14px;
      }
    }
    .icon {
      flex-shrink: 0;
      padding: 1rem;
    }
  }
}

.article-layout2 {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #bfbfbf;
  margin-bottom: 1rem;
  color: $main;
  .icon {
    transition: all .4s;
  }
  &:not(.no-hover):hover {
    .ttl {
      color: $blue;
    }
    .icon {
      transform: translateX(.5rem);
    }
  }
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  &-thumb {
    flex-basis: 26%;
    flex-shrink: 0;
    .img {
      height: 100%;
      object-fit: cover;
    }
    .link {
      aspect-ratio: 1/1;
      &:hover {
        .img {
          opacity: 0.8;
        }
      }
    }
  }
  &-txt {
    flex-grow: 1;
    .ttl {
      font-size: 1rem;
      transition: all .4s;
    }
    .link {
      text-decoration: underline;
      color: $main;
      &:hover {
        color: $blue;
      }
    }
    .time {
      color: $black;
    }
  }
  @include mq(sp) {
    &-txt {
      line-height: 1.4;
      .ttl {
        margin-bottom: 0.25rem;
      }
    }
  }
}
.article-layout3 {
  &-item {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    .ttl-sub {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
    }
  }
}

.area-select {
  background-color: rgba(239, 239, 239, 0.4);
  padding: 1.5rem;
}

.tel-box {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
  justify-content: center;
  margin-top: 1rem;
  .txt {
    font-weight: bold;
    font-size: 10px;
    line-height: 1;
  }
  .num {
    color: $orange;
    font-size: 25px;
    font-weight: 500;
    line-height: 1;
  }
}


.breadcrumb {
  font-weight: 500;
  font-size: 12px;
  margin: 1rem auto;
  display: flex;
  padding-bottom: 1rem;
  overflow-x: auto;
  &-item {
    flex-shrink: 0;
    &:last-child {
      a,
      span {
        &::after {
          display: none;
        }
      }
    }
    a,
    span {
      white-space: nowrap;
      &::after {
        content: ">";
        display: inline;
        margin: 0 0.5rem;
        color: $black;
      }
    }
    a {
      color: $main;
      font-weight: bold;
      &:hover {
        color: $blue;
      }
    }
  }
  @include mq(sp) {
    //padding: 0 5%;
    width: 90%;
  }
}

// ol
ol {
  ::marker {
    color: $main !important;
    font-weight: bold;
  }
  li {
    padding-left: 0.5rem;
    margin-left: 1.25rem;
    margin-bottom: 0.75rem;
  }
}
.ol-alphabet {
  &-item {
    list-style: upper-alpha;
  }
}

.table {
  background-color: white;
  th,
  td {
    padding: 1.5rem;
  }
  &-group {
    background-color: rgba(60, 122, 195, 0.05);
    border-top: 1px solid #fff;
    &:nth-child(odd) {
      background: rgba(60, 122, 195, 0.1);
    }
  }
  &-title {
    background-color: $main;
    color: #fff;
    text-align: center;
    font-weight: 500;
    padding: 2rem;
  }
}

.no-scroll {
  overflow: hidden;
}