@use "_var" as *;
@use "mixins" as *;
$bg-icon-size: 1rem;

$bg-opacity: 0.08;
$bg-opacity-015: 0.15;
.bg-img {
    pointer-events: none;
}
.bg-cnt {
    position: relative;
    z-index: 3;
}
.bg-cnt-z1 {
    position: relative;
    z-index: 1;
}
.bg-blue {
    background-color: $main;
    overflow: hidden;
    position: relative;
    & > .bg-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: url(../img/bg-white.svg);
        background-size: 2rem;
        opacity: $bg-opacity-015;
        &.bg-img-no-zindex {
            z-index: auto;
        }
    }
    &-l {
        background-color: $blue-l;
        overflow: hidden;
        position: relative;
        & > .bg-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            width: 100%;
            height: 100%;
            background-image: url(../img/bg-main.svg);
            background-size: $bg-icon-size;
            opacity: $bg-opacity;
        }
    }
}

.bg-white {
    background-color: white;
    overflow: hidden;
    position: relative;
    .bg-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: url(../img/bg-main.svg);
        background-size: $bg-icon-size;
        opacity: $bg-opacity;
    }
    &-plan {
        background-color: white;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        padding: 0 1rem;
        margin: -2px 0;
        @include mq(sp) {
            padding: 0;
        }
    }
}

.bg-gray {
    background-color: #f9f9f9;
    overflow: hidden;
    position: relative;
    .bg-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: url(../img/bg-main.svg);
        background-size: $bg-icon-size;
        opacity: $bg-opacity;
    }
}

.bg-grad-red {
    background: $gradRed2;
}