@use "../_global/var" as *;
@use "../_global/mixins" as *;
.banner-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  .item {
    flex-basis: calc((100% - 1.5rem * 3) / 4);
    &:nth-child(4n) {
      margin-right: 0;
    }
    .link {
      aspect-ratio: 16/8.7;
      &:hover {
        opacity: 0.6;
      }
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  @include mq(sp) {
    gap: 1rem;
    width: 90%;
    margin: auto;
    .item {
      flex-basis: calc((100% - 1rem) / 2);
    }
  }
}

.footer-links {
  background: #f9f9f9;
  font-weight: 500;
  margin-top: 5rem;
  &-inr {
    .ttl {
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }
  }
  .term-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
    .item {
      margin-bottom: 0.25rem;
      display: flex;
      align-items: center;
      &::after {
        content: "/";
        margin: 0 0.25rem;
        font-size: 12px;
      }
      &:last-child::after {
        content: none;
      }
      a {
        font-size: 12px;
        line-height: 1;
        &:hover {
          color: $blue;
        }
      }
    }
  }
  &-inr {
    width: 90%;
    margin: auto;
  }
}

.footer {
  background-color: #eee;
  font-weight: 500;
  .logo {
    width: 195px;
    margin: auto;
  }
  &-nav {
    display: flex;
    justify-content: center;
    font-size: 12px;
  }
  &-btm-nav {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    font-size: 1.125rem;
    font-weight: bold;
    color: $main;
    a {
      text-decoration: underline;
      letter-spacing: 2px;
    }
  }
  &-sns {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    margin: 2rem auto;
    .item {
      padding: 1rem;
      background-color: #fff;
      width: 225px;
      color: $black;
      text-align: center;
      .icon {
        width: 2rem;
        margin: 0 auto 0.5rem;
      }
    }
  }
  &-category-title {
    font-size: 1pc;
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 20px;
    &::before {
      content: "";
      display: inline-block;
      width: 9pt;
      height: 1px;
      background-color: $black;
      position: absolute;
      top: 50%;
      left: 0;
    }
  }
  &-category-children-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &-category-children {
    width: 50%;
  }
  &-category-item {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  &-anchor {
    font-size: 12px;
  }
  &-copyright {
    color: #fff;
    background-color: $blue;
  }
  &-sitemap-item {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  &-copyright-inner {
    display: flex;
    align-items: center;
    height: 47px;
    justify-content: center;
  }
  &-banner {
    background-color: white;
    // margin-top: 4rem;
  }
  @include mq(sp) {
    &-nav {
      order: 3;
      flex-wrap: wrap;
      width: 90%;
      margin: auto;
      &-top {
        order: 3;
        flex-basis: 100%;
        margin-top: 2rem;
        .logo {
          margin: 0 auto 2rem;
        }
      }
      &-mid {
        flex-basis: 100%;
        padding-top: 0;
        order: 1;
      }
      &-btm {
        flex-basis: 100%;
        order: 2;
      }
      .hr {
        display: none;
      }
    }
    &-sitemap {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem 1rem;
      &-item {
        margin-bottom: 0;
      }
    }
    &-sns {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .item {
        flex-basis: calc((100% - 1rem) / 2);
        &:last-child {
          margin-top: 0;
          .icon {
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}

.footer-custom {
  margin-top: 11rem;
}

.pagetop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  z-index: 99;
  background-color: #ccc;
  border-radius: 50%;
  transition: opacity 0.3s cubic-bezier(0.13, 0.78, 0.38, 0.98);
  &-text {
    display: none;
  }
  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -4px;
    margin-left: -7.5px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-top: 2px solid #666;
    border-right: 2px solid #666;
    transform: rotate(-45deg);
  }
  &:hover {
    opacity: 0.5;
  }
  @include mq(sp) {
    display: none;
  }
}

.fixed-footer-nav {
  position: fixed;
  z-index: 998;
  bottom: 0;
  left: 0;
  width: 100%;
  .inr {
    display: flex;
    width: 100%;
    color: white;
  }
  .item {
    flex-basis: calc(100% / 3);
    text-align: center;
    background: linear-gradient(180deg, #a4c6ee 0%, #3c7ac2 9.38%, #004f9d 100%);
    padding: 9px 0;
    font-size: 10px;
    &:nth-child(2) {
      border-left: 1px solid;
      border-right: 1px solid;
    }
    span {
      display: block;
      line-height: 1;
    }
    svg {
      height: 1.5rem;
    }
    .icon {
      padding: 4px;
    }
  }
}
