@use "../_global/var" as *;
@use "../_global/mixins" as *;
.label-chip {
  padding: .1rem .8rem .2rem;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 1rem;
  &.required {
    background-color: $label-chip-required;
  }
  &.any {
    background-color: $label-chip-any;
  }
  &__flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.c-red {
  display: inline-block;
  color: red;
  margin-left: .5rem;
  vertical-align: middle;
  font-size: .9rem;
  font-weight: 500;

  @include mq-max(420px) {
    font-size: .8rem;
  }
}
