@use "../_global/var" as *;
@use "../_global/mixins" as *;
.fixed-banner {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 2rem;
  opacity: 0;
  transform: translateY(1rem);
  transition: all 1s;
  pointer-events: none;
  &.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: visible;
  }
  .inr {
    max-width: 1640px;
    margin: auto;
    position: relative;
    z-index: 1;
    .cnt {
      width: 250px;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 2%;
      .icon-close {
        cursor: pointer;
        position: absolute;
        z-index: 2;
        top: -1rem;
        right: -1rem;
        background: white;
        line-height: 1;
        padding: 1rem;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        border: 2px solid $black;
        svg {
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-47%,-52%);
        }
        &:hover {
          background-color: $black;
          path {
            stroke: white;
          }
        }
      }
    }
    .link {
      padding: 1.5rem;
      background-color: white;
      box-shadow: 0px 4px 10px rgba(200, 200, 200, 0.25);
      span {
        display: inline-block;
      }
      .txt {
        text-decoration: underline;
        color: #0038EF;
        display: flex;
        align-items: center;
        grid-area: 1rem;
        margin-top: .5rem;
        justify-content: space-between;
        .icon {
          // width: 24px;
          flex-shrink: 0;
        }
      }
    }
  }
  @include mq(sp) {
    top: initial;
    left: 0;
    bottom: 0;
    right: 1rem;
    margin: 0 !important;
    .inr {
      .cnt {
        top: initial;
        bottom: 1rem;
        right: initial;
        left: 1rem;
        width: 168px;
      }
      .link {
        padding: 0.5rem;
        .txt {
          font-size: 10px;
        }
      }
    }
  }
}


// *********** sidebar *************
.new-post {
  padding: 1.5rem;
  &-cnt {
    margin-top: 1.5rem;
  }
}

.recommendation {
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }

  &-list {
    margin-top: 2rem;
    margin-left: 1rem;
  }

  &-item {
    border-bottom: 1px solid #bfbfbf;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .thumb {
      width: 30%;
      .img {
        height: 100%;
        object-fit: cover;
      }
    }
    .block {
      width: 60%;
      &-ttl-sub {
        line-height: 1.5;
        font-size: 14px;
      }
      &-date {
        font-size: 12px;
        color: #bfbfbf;
      }
    }

    @include mq(sp) {
      display: flex;
      align-items: center;
      a {
        flex-wrap: wrap;
      }
      .thumb {
        width: 100%;
        margin-bottom: 1rem;
        .img {
          height: 100%;
          object-fit: cover;
        }
      }

        .block {
          justify-content: space-between;
          display: inline-block;
          width: 90%;
          &-ttl-sub {
            margin-bottom: 0.5rem;
            font-size: 14px;
          }
          &-date {
            font-size: 14px;
            color: #bfbfbf;
          }
        }
        .arrow-icon {
          display: inline-block;
          width: 5%;
          align-items: center;
        }
      }
    }
  }

