@use "_var" as *;
@use "mixins" as *;
.btn {
  display: inline-block;
  cursor: pointer;
}

.btn-radius {
  margin-left:10px;
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 3rem;
  position: relative;
  z-index: 1;
  min-width: 258px;
  span {
    width: max-content;
    position: relative;
    z-index: 3;
    color: white;
    font-weight: bold;
    display: inline-block;
  }
  .text-sz{
    font-size: 14px;
  }
  &::before {
    content: "";
    transition: all 0.4s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 3rem;
  }
  &.grad-green {
    background: linear-gradient(360deg, #9deec7 0%, #45ca8a 8.33%, #0d9b57 100%);
    &::before {
      background: linear-gradient(180deg, #9deec7 0%, #45ca8a 8.33%, #0d9b57 100%);
    }
    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }
  &.grad-blue {
    background: linear-gradient(360deg, #a4c6ee 0%, #3c7ac2 9.38%, #004f9d 100%);

    &::before {
      background: linear-gradient(180deg, #a4c6ee 0%, #3c7ac2 9.38%, #004f9d 100%);
    }
    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }
  &.grad-red {
    background: linear-gradient(360deg, #ffc8c8 0%, #e65454 8.33%, #b91d1d 100%);
    &::before {
      background: linear-gradient(180deg, #ffc8c8 0%, #e65454 8.33%, #b91d1d 100%);
    }
    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }
  &.grad-orange {
    background: linear-gradient(360deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
    &::before {
      background: linear-gradient(180deg, #f7ccb5 0%, #ff8d50 9.38%, #ff6e21 100%);
    }
    &:hover {
      &::before {
        opacity: 0;
      }
    }
  }
  &.main {
    background-color: $main;
    color: white;
    &:hover {
      background-color: #dbe4f1;
      color: $main;
    }
  }
  &.red {
    background: linear-gradient(180deg, #FFC8C8 0%, #E65454 8.33%, #B91D1D 100%);
    color: white;
    &:hover {
      color: white;
    }
  }
  &[data-submit-disabled="disabled"] {
    cursor: no-drop;
    background: #ccc;
    &::before {
      cursor: no-drop;
      background: #ccc;
    }
    &:hover {
      &::before {
        opacity: 0;
      }
    }
    input, button {
      cursor: no-drop;
    }
  }
}
.btn-radius2 {

  padding: 0.5rem 1rem;
  text-align: center;
  line-height: 1;
  font-weight: bold;
  border-radius: 3em;
  background-color: white;
  border: 1px solid $main;
  color: $main;
  &-2 {
    @extend .btn-radius2;
    background-color: $main;
    color: white;
  }
}
.btn-radius3 {
  padding: 2.5rem 3rem;
  text-align: center;
  line-height: 1;
  font-weight: bold;
  border-radius: 1em;
  background-color: white;
  border: 1px solid $main;
  color: $main;
  &-2 {
    @extend .btn-radius3;
    background-color: $main;
    color: white;
  }
}

.btn-round {
  background-color: $main;
  font-weight: 500;
  color: white;
  line-height: 1.2;
  padding: 16px 10px;
  box-shadow: 0px 2px 0px #ccc;
  border-radius: 4px;
  text-align: center;
  width: auto;
  &:hover {
    background-color: white;
    color: $main;
  }
  &.white {
    background-color: white;
    color: $main;
    &.area {
      box-shadow: 0px 5px 0px #283135;
      color: #000000;
    }
    &:hover {
      transform: translateY(1px);
      box-shadow: 0 0 0 #333;
    }
  }
}

.btn-square {
  background-color: white;
  line-height: 1.2;
  border: 1px solid;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  width: auto;
}

// ****** menu *******
.menu-btn {
  font-size: 9px;
  cursor: pointer;
  overflow: hidden;
  &::after {
    content: "メニュー";
  }
  .icon {
    width: 2rem;
    height: 16px;
    position: relative;
    z-index: 1;
    margin: 0 auto 0.25rem;
    .line {
      width: 100%;
      height: 2px;
      background-color: $black;
      position: absolute;
      z-index: 1;
      transition: transform .4s;
      transform: rotate(0);
    }
    .top {
      top: 0;
      left: 0;
    }
    .mid {
      top: 50%;
      left: 0;
      transform: rotate(0) translateY(-50%);
    }
    .btm {
      bottom: 0;
      left: 0;
    }
  }
  @include mq(sp) {
    &-txt {
      line-height: 1;
      margin-top: 0.5rem;
    }
  }
}

// *********** checkbox *************

.label-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  &-input {
    margin: 0;
    width: 0;
    opacity: 0;
    &:hover {
      & > .label-checkbox-dummy {
        background: #dddddd !important;
        border: solid 2px #333333;
      }
    }
    & + .label-checkbox-dummy {
      // background: #dddddd !important;
      // border: solid 2px #333333;
    }
    &:checked {
      & + .label-checkbox-dummy {
        // border: solid 2px #333333;
        // background: #ffffff;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url(../img/icon_check_main.svg);
          background-size: contain;
        }
      }
    }
  }
  &-dummy {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    background: $gray;
    border: 1px solid $main;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(../img/icon_check.svg);
      background-size: contain;
    }
  }
  &-txt {
    margin-left: 12px;
    display: block;
    font-weight: bold;
  }
}

.calendar-submit {
  color: white;
  position: relative;
  z-index: 3;
  font-weight: bold;
  display: inline-block;
  background: transparent;
  font-size: 16px;
  width: 100%;
}

// *********** toggle *************
.area-select-container {
  display: none;
}

.toggle-all-button {
  display: none;
}

// *********** 新規追加した検索ボタンのスタイル *************
.new-button-margin {
  margin-top: 10px;
  margin-bottom: 5px ;
}
.new-button-position {
  display: flex;
  justify-content: center;
}