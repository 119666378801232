@use "_breakpoints" as *;


//==========================================================
//	メディアクエリ
//==========================================================
$breakpoint: (
	phone-small: 'screen and (max-width: 377px)',
	phone-sm-middle-s: 'screen and (max-width: 390px)',
	phone-sm-middle-m: 'screen and (max-width: 440px)',
	phone-sm-middle-l: 'screen and (max-width: 540px)',
	phone-sm-middle-xl: 'screen and (max-width: 580px)',
	phone-medium: 'screen and (max-width: 620px)',
	sp: 'screen and (max-width: 767px)',
	tab: 'screen and (min-width: 768px)',
	pc: 'screen and (min-width: 1025px)',
	l: 'screen and (min-width: 1600px)',
	2l: 'screen and (min-width: 1800px)'
);

@mixin mq($bp) {
	@media #{map-get($breakpoint, $bp)} {
		@content;
	}
}

@mixin mq-max($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}
