@use "../_global/var" as *;
@use "../_global/mixins" as *;
// ************* calender ***************
.calender {
  padding: 1.25rem;
  .ttl {
    text-align: center;
    color: white;
    font-size: 1.25rem;
    line-height: 1.2;
  }
  .caption {
    background-color: white;
    padding: 0.6rem;
    font-size: 10px;
  }
  &-cnt {
    margin-top: 1.5rem;
    font-weight: 500;
    .sunday,
    .holiday {
      color: $red;
    }
    .num {
      line-height: 1.2;
      .m {
        font-size: 10px;
      }
    }
    .week {
      .row {
        display: flex;
        align-items: center;
        background-color: $gray2;
      }
      &-day {
        flex-basis: calc(100% / 7);
        padding: 10px;
        text-align: center;
      }
    }
    .day {
      .row {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        background-color: $gray2;
        &:last-child {
          .cnt {
            border-bottom: 1px solid #ececec;
          }
        }
      }
      .cnt {
        flex-basis: calc(100% / 7);
        padding: 2% 1%;
        text-align: center;
        background-color: white;
        border: 1px solid #ececec;
        border-right: none;
        border-bottom: 0;
        display: flex;
        flex-direction: column;
        &:nth-child(7n) {
          border-right: 1px solid #ececec;
        }
        &[data-calendar-date-click] {
          cursor: pointer;
        }
        .icon {
          min-height: 1.25rem;
          &.possible {
            &::after {
              content: "";
              $width: 1rem;
              width: $width;
              height: $width;
              border: 2px solid $yellow;
              border-radius: 50%;
              margin: 0.25rem auto 0;
            }
          }
          &.inpossible {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &::after {
              content: "";
              width: 1rem;
              height: 2px;
              background-color: #d9d9d9;
              margin: 0.25rem auto 0;
            }
          }
        }
      }
    }
  }
  &-form {
    padding: 4%;
    background-color: white;
    text-align: left;
    .select,.input {
      border: 1px solid #D7D7D7;
      border-radius: 5px;
      line-height: 1.2;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
    }
    .form-label {
      min-width: 6rem;
    }
    .form-item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .select-box,.input-box {
        flex-grow: 1;
        .select {
          width: 100%;
          font-size: 16px;
        }
      }
      .select-box,
      .input-box,
      .check-box {
        @include mq-max(512px) {
          width: 100%;
        }
      }
    }
    .form-cnt {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      @include mq(sp) {
        display: block;
      }
      &-item {
        flex-basis: calc((100% - 1rem) / 2);
        @include mq(sp) {
          margin-bottom: 1rem;
        }
        &.name {
          .input-box {
            @include mq(sp) {
              width: 34%;
            }
            @include mq-max(512px) {
              width: 100%;
            }
          }
        }
        &.tel,&.mail,&.col {
          flex-basis: 100%;
        }
        &.nowrap {
          .form-item {
            flex-wrap: nowrap;
          }
        }
        &.temples {
          flex-basis: 100%;
          .form-item {
            //flex-wrap: nowrap;
            //@include mq(sp) {
            //  flex-wrap: wrap;
            //}
            .form-label {
              width: 100%;
              //@include mq(sp) {
              //  width: 100%;
              //}
            }
          }
          .input-box {
            margin-left: 2rem;
            @include mq(sp) {
              margin-left: 1rem;
            }
            .temple-form {
              &-area {
                margin-bottom: 1rem;
                &__title {
                  display: inline-flex;
                  align-items: center;
                  justify-content: flex-start;
                  &.accordion2-trig-icon {
                    margin-left: 0;
                    .icon-circle-plus {
                      margin-left: 1rem;
                    }
                  }
                }
                .accordion2-cnt {
                  margin-left: 2rem;
                  @include mq(sp) {
                    margin-left: 1rem;
                  }
                  .label-checkbox {
                    display: inline-flex;
                    .label-checkbox-dummy {
                      background: white;
                      border: 1px solid #D7D7D7;
                    }
                    .label-checkbox-txt {
                      margin-left: 10px;
                      margin-right: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .submit-box {
      text-align: center;
      margin-top: 1.25rem;
      input {
        color: white;
        position: relative;
        z-index: 3;
        font-weight: bold;
      }
    }
    .select-box {
      position: relative;
      z-index: 1;
      &::after {
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: #D7D7D7 transparent transparent transparent;
        transform: rotate(0deg);
        position: absolute;
        z-index: 1;
        top: 50%;
        right: .75rem;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }
  }
}

.form-box {
  max-width: 480px;
  margin: auto;

  &-item {
    margin-bottom: 1.5rem;
  }
}

.form-box.wide {
  max-width: 530px;
}

.bg-red {
  background-color: #FEF4F5;
}

.input-ttl, .input-flex, .input-group {
  width: 100%;
}

.input-group-select {
  font-size: 16px;
}

.input-ttl {
  margin-bottom: .5em;
}

.input-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-item {
    width: 49%;
  }
}

.input-radio-box {
  &-label {
    padding: 5px 13px;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
  }

  &-checkbox {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    background: white;
    border: 2px solid #F77C00;
    border-radius: 30px;
  }

  .input-radio-box-label:has(.label-checkbox-input:checked) {
    background-color: #ffe9d5;
    border: 1px solid #F77C00;
  }

  .label-checkbox-input:checked + .input-radio-box-checkbox::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background: #F77C00;
    border-radius: 30px;
  }
}

.input-radio-box.sm-50 {
  @include mq-max(500px) {
    width: 46%;
  }
}

.input-check-box {
  margin-bottom: 1rem;

  .input-radio-box-checkbox {
    border: 2px solid #004f9d;
  }

  .input-radio-box-label:has(.label-checkbox-input:checked) {
    background-color: #e6f2ff;
    border: 1px solid #004f9d;
  }

  .label-checkbox-input:checked + .input-radio-box-checkbox::before {
    background: #004f9d;
  }
}

.privacy-box {
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

.pp.privacy-box .ttl {
  font-size: 16px;
  margin: 1.5rem auto 0.5rem;
  color: #393939;
}

.request-info-cnt .privacy-box label.label-checkbox,
.pp.privacy-box label.label-checkbox{
  width: 90%;
  margin: auto;
  max-width: 300px;
  justify-content: center;
}
.pp.privacy-box .link {
  display: inline;
  color: #3c7ac3;
  text-decoration: underline;
}