@use "_mixins" as *;
@use "_var" as *;


// ***** base style ******
*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
*::before,
*::after {
    display: block;
}
html {
    width: 100%;
    height: -webkit-fill-available;
    font-size: 14px;
    scroll-padding-top: 62px;
}
body {
    margin: 0;
    font-family: $basefont;
    font-weight: 600;
    color: $black;
    word-break: break-all;
    line-height: 1.8;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    background-image: url(../img/bg-main.svg);
    background-color: rgba(253, 253, 253, 0.9);
    background-blend-mode: lighten;
    background-size: 1rem;
    &.archive {
        .page-en-name {
            &::before {
                content: "infomation";
                font-style: italic;
                font-size: 1.5rem;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}

a {
    transition: all 0.4s;
    text-decoration: none;
    display: block;
}
a,
span {
    color: inherit;
}
ul,
dl {
    list-style: none;
}
ul {
    margin: 0;
    li {
        margin: 0;
    }
}
input {
    &:hover {
        cursor: pointer;
    }
    &:focus-visible {
        outline: none;
    }
}
input,
textarea,
select {
    appearance: none;
    line-height: 1.6;
    border: none;
}
input,
textarea {
    display: inline-block;
    background: transparent;
    font-size: 16px;
    width: 100%;
    &::placeholder {
        font-size: 1rem;
        color: #b2b2b2;
        font-weight: normal;
    }
    &:hover {
        cursor: pointer;
    }
}
select {
    text-overflow: ellipsis;
    cursor: pointer;
    border-radius: 4px;
    background-color: white;
    color: $black;
}
button {
    border: 0;
    outline: none;
    appearance: none;
    cursor: pointer;
    background: none;
}
hr {
    border-color: $gray;
    border-width: 1px 0 0 0;
    margin: 1rem auto;
}
img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
}
iframe {
    display: block;
    width: 100%;
    border: none;
}
address {
    font-style: 400;
    margin-top: 2rem;
}
table {
    width: 100%;
    border-collapse: collapse;
}
address {
    margin: 0;
}
strong {
    font-size: 1em;
    font-weight: 700;
}
.body {
    position: relative;
    z-index: 3;
}
.en {
    font-family: $en-font;
}
.mincho {
    font-family: $mincho;
}

.bg-grad {
    &-green {
        background: $gradGeen;
    }
}

.icon-circle {
    border-radius: 50%;
    border: 1px solid $main;
    aspect-ratio: 1/1;
    .img {
        height: 100%;
        object-fit: cover;
    }
}

.block {
    display: block;
}
.sp-block {
    display: none;
    @include mq(sp) {
        display: block;
    }
}
.sp-hidden {
    display: block;
    @include mq(sp) {
        display: none;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
    -moz-appearance:textfield !important;
}